import { catchError, tap } from 'rxjs/operators';
import { CommunicationService } from './communication.service';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { throwError } from 'rxjs';
import { Storage } from '@ionic/storage';
import { UtilsService } from './utils.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { Capacitor } from '@capacitor/core';
import { AuthDataModel } from '../shared/models/auth.models';
import { ProfileService } from './profile.service';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { BiometricService } from './biometric.service';
import { RegisterComponent } from '../shared/components/register/register.component';
import { DataLayerService } from './data-layer.service';
import * as momentNs from 'moment';
import { Profile } from '../shared/models/register.models';
import { ToastService } from '../shared/components/toast/toast.service';
import { AlertService } from '../shared/components/alert/alert.service';
import { OveralMessageComponent } from '../shared/components/overal-message/overal-message.component';
import { RenewSessionComponent } from '../shared/components/renew-session/renew-session.component';
import { ModalService } from '../shared/components/modal/modal.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from './app.configuration.service';
import { PushNotificationsService } from './push-notifications.service';
import { NotificationsService } from './notifications.service';
import { AmplitudeService } from './amplitude.service';
import { StrapiService } from './strapi.service';
const moment = momentNs;

@Injectable()
export class AuthService {
  @Output() public refreshLoginevent = new EventEmitter<any>();
  @Output() public readyToInvestEvent = new EventEmitter<any>();
  @Output() public readyToBid = new EventEmitter<any>();
  @Output() public finishCompleteRegister = new EventEmitter<any>();
  @Output() public logoutEvent = new EventEmitter<any>();
  @Output() public profileGeneratedEvent = new EventEmitter<any>();

  public profile: Profile = {};

  private expirationCurrent = 0;
  public isMobile: boolean;
  public requestRefreshTokenInProcess = false;
  authData: any;
  timeout: any;
  userLogged: boolean;
  userEmail: any;
  isLogoutFomMenu = false;

  constructor(
    private storage: Storage,
    private communicationService: CommunicationService,
    private utilsService: UtilsService,
    private toastService: ToastService,
    private alertService: AlertService,
    private modalService: ModalService,
    private translate: TranslateService,
    private aletService: AlertService,
    private profileService: ProfileService,
    private localize: LocalizeRouterService,
    private navCtrl: NavController,
    private biometricService: BiometricService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private http: HttpClient,
    private appConfigurationService: AppConfigurationService,
    private notificationsService: NotificationsService,
    private amplitudeService: AmplitudeService,
    private pushService: PushNotificationsService,
    private strapiService: StrapiService
  ) {}

  async login(params: any) {
    this.userEmail = params.email;
    if (Capacitor.getPlatform() !== 'web') {
      this.isMobile = true;
    } else {
      this.isMobile =  false;
    }
    const paramsdata = {
      username: params.email,
      password: params.password.substring(0, 30),
      mobile: this.isMobile
    };
    return new Promise((resolve) => {
      this.communicationService.post('apisecurity/auth/v1/login', paramsdata).subscribe(
        (response) => {
          if (response) {
            const internalId = response.internal_id;
            localStorage.setItem('internalId', internalId);
            this.storage.set('USERNAME', params.email);
            const data: AuthDataModel = {
              username: params.email,
              accessToken: response.access_token,
              refreshToken: response.refresh_token,
            };

            if (response.mobile_token && response.mobile_token !== undefined) {
              data.mobileToken = response.mobile_token;
            } else {
              data.mobileToken = undefined;
            }
            this.expirationCurrent = this.utilsService.getExpirationDate(data.accessToken);
            this.saveAuthData(data).then((result) => {
              this.authData = result;
              this.isLogoutFomMenu = false;
              resolve(true);
            });
            this.storage.set('NAME', response.name);
            this.setAvatarColor();

            const model = {
              username: response.email,
              mailVerified: response.emailVerified,
              phone: response.mobile,
              mobileVerified: response.mobileVerified,
              name: response.name,
              profileType: response.profileType
            };
            this.storage.set('USERDATA', model);
            this.pushService.setOneSignalMainData(response.email);
          } else {
            this.authData = null;
            resolve(false);
          }
        },
        (error) => {
          if (this.authData === null || this.authData === undefined) {
            this.authData = null;
          }
          resolve(false);
          if (error.status === 412) {
            this.showValidateMailModal();
          } else {
            const errMsgLogin: any = this.translate.instant('LOGIN.ERROR_INVALID_USER_PASS');
            this.toastService.showToast(errMsgLogin, 'warning');
          }
        }
      );
    });
  }

  postLogin(param) {
    this.userEmail = param.email;
    const internalId = param.internal_id;
    localStorage.setItem('internalId', internalId);
    this.storage.set('USERNAME', param.email);
    const data: AuthDataModel = {
      username: param.email,
      accessToken: param.access_token,
      refreshToken: param.refresh_token,
    };

    if (param.mobile_token && param.mobile_token !== undefined) {
      data.mobileToken = param.mobile_token;
    } else {
      data.mobileToken = undefined;
    }
    this.expirationCurrent = this.utilsService.getExpirationDate(data.accessToken);
    this.saveAuthData(data).then((result) => {
      this.authData = result;
      this.isLogoutFomMenu = false;
    });
    this.storage.set('NAME', param.name);
    this.setAvatarColor();

    const model = {
      username: param.email,
      mailVerified: param.emailVerified,
      phone: param.mobile,
      mobileVerified: param.mobileVerified,
      name: param.name,
      profileType: param.profileType
    };
    this.storage.set('USERDATA', model).then(() => {
      this.profileService.getProfile(this.userEmail).subscribe(
        (response) => {
          this.profile = response;
          this.storage.set('PROFILE', response);
          let documentType: number;
          if (response.identityDocumentDni !== null && response.identityDocumentDni !== undefined) {
            documentType = 1;
          } else if (response.identityDocumentNie !== null && response.identityDocumentNie !== undefined) {
            documentType = 2;
          } else if (response.identityDocumentPassport !== null && response.identityDocumentPassport !== undefined) {
            documentType = 3;
          }
          const wallet = {
            walletstatus: response.walletstatus,
            docType: documentType,
            refCode: response.referralCode,
          };
          this.storage.set('WALLET', wallet);
          setTimeout(() => {
            this.isSuceesLogin();
            this.controlTokenTime();
          }, 500);
          this.amplitudeService.trackLogin(this.userEmail);
        },
        (error) => {
          this.storage.set('WALLET', undefined);
          setTimeout(() => {
            this.isSuceesLogin();
            this.controlTokenTime();
          }, 500);
        }
      );
    });
  }

  showValidateMailModal() {
    try {
      this.modalService.removeModal();
    } catch {}

    const modalParams = {
      image: 1,
      text: this.translate.instant('LOGIN.VALIDATE_MAIL'),
      btnList: [
        {
          type: 'primaryDark',
          index: 1,
          title: this.translate.instant('REGISTER.PASSPORT.BTN'),
        }
      ],
    };

    this.modalService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
        componentRef.btnEvent.subscribe((resp) => {
          this.modalService.removeModal();
        });
      });
      modal.onClose().subscribe();
    });
  }

  getAuthData(): any {
    return this.authData;
  }

  setAvatarColor() {
    const colorList = ['linear-gradient-1', 'linear-gradient-2', 'linear-gradient-3', 'linear-gradient-4', 'linear-gradient-5', 'linear-gradient-6', 'linear-gradient-7'];
    const color = colorList[Math.floor(Math.random() * colorList.length)];
    this.storage.set('COLOR', color);
  }

  saveAuthData(data: any): Promise<any> {
    this.strapiService.strapiLogin(data.accessToken);
    return this.storage.set('AUTHDATA', data);
  }

  async loadAuthData() {
    this.authData = await this.storage.get('AUTHDATA');
    if (this.authData) {
      if (this.expirationCurrent === 0) {
        this.expirationCurrent = this.utilsService.getExpirationDate(this.authData.accessToken);
      }
    }
  }

  async validateToken(): Promise<boolean> {
    await this.loadAuthData();

    if (!this.authData) {
      this.logout();
      return Promise.resolve(false);
    }

    return new Promise((resolve) => {
      if (
        !this.utilsService.isExpiredTOKEN(this.utilsService.getExpirationDate(this.authData.accessToken)) ||
        !this.utilsService.isExpiredTOKEN(this.utilsService.getExpirationDate(this.authData.refreshToken)) ||
        (this.isMobile && !this.utilsService.isExpiredTOKEN(this.utilsService.getExpirationDate(this.authData.mobileToken)))
      ) {
        resolve(true);
      } else {
        if (this.getMobileTokenexpired()) {
          resolve(false);
          this.logout();
        } else {
          resolve(true);
        }
      }
    });
  }

  logout(param?:boolean) {
    let navigationExtras: NavigationExtras;
    this.expirationCurrent = -1;
    this.authData = null;
    this.removeTokens();
    if (param) {
      this.isLogoutFomMenu = true;
    }
    if (this.isLogoutFomMenu) {
      navigationExtras = {
        state: {
          isLogout: true
        },
      };
      this.storage.set('LASTPAGE', navigationExtras);
      this.navCtrl.navigateRoot([this.localize.translateRoute(`/login`)], navigationExtras);
      //this.posthogService.reset();
    } else {
      navigationExtras = {
        state: {
          isLogout: true
        },
      };
      this.navCtrl.navigateRoot([this.localize.translateRoute(`/login`)], navigationExtras);
    }
    this.notificationsService.resetList();
    this.logoutEvent.emit();
    this.biometricService.makeMobileVibrate();
    this.pushService.removeOneSignalMainData();
  }

  isRefreshExpired() {
    return this.utilsService.isExpiredTOKEN(this.utilsService.getExpirationDate(this.authData.refreshToken));
  }
  isMobileExpired() {
    return this.utilsService.isExpiredTOKEN(this.utilsService.getExpirationDate(this.authData.mobileToken));;
  }
  removeTokens() {
    this.storage.remove('AUTHDATA');
    this.storage.remove('ISFIRST');
    this.storage.remove('USERNAME');
    this.storage.remove('NAME');
    this.storage.remove('COLOR');
    this.storage.remove('WALLET');
    this.storage.remove('LASTPAGE');
    this.storage.remove('USERDATA');
    this.storage.remove('ISINVESTOR');
    this.storage.remove('UTM');
    this.storage.remove('PROFILE');
    this.storage.remove('OVERVIEWPAGE');
    this.storage.remove('PROMO');
    this.storage.remove('CHAT_OPENED');
    this.storage.remove('HS_CONTACT_ID');
    this.storage.remove('HS_SURNAME_USER');
    this.storage.remove('HS_PRODUCT_ID');
    this.storage.remove('HS_SURNAME_USER');
    this.storage.remove('PROMO');
    this.storage.remove('STRAPITOKEN')
    localStorage.removeItem('internalId');
    this.strapiService.strapi_api_token = null;
  }

  refreshTokens() {
    this.requestRefreshTokenInProcess = true;
    return this.communicationService.post('apisecurity/auth/v1/refresh', {}).pipe(
      tap((response: any) => {
        this.requestRefreshTokenInProcess = false;
        if (response) {
          this.storage.get('AUTHDATA').then((val) => {
            const data: AuthDataModel = {
              username: this.authData.email,
              accessToken: response.access_token,
              refreshToken: response.refresh_token,
              mobileToken: val.mobileToken
            };
            this.expirationCurrent = this.utilsService.getExpirationDate(data.accessToken);
            this.saveAuthData(data).then((result) => {
              this.authData = result;
            });
          });
        } else {
          return throwError('response is undefined');
        }
      }),
      catchError((res) => {
        this.requestRefreshTokenInProcess = false;
        if (this.isMobileExpired() && res.status === 401) {
          this.authData = null;
          this.storage.clear();
        }
        return throwError(res);
      })
    );
  }

  hasToRefresh() {
    return this.utilsService.isExpiredTOKEN(this.expirationCurrent) && this.authData;
  }

  getMobileTokenexpired() {
    return this.utilsService.isExpiredTOKEN(this.utilsService.getExpirationDate(this.authData.mobileToken));
  }

  isLogged() {
    this.storage.get('AUTHDATA').then((val) => {
      if (val !== null && val.username !== undefined) {
        this.userLogged = true;
        this.controlTokenTime();
      } else {
        this.userLogged = false;
      }
    });
  }

  controlTokenTime() {
    const tokenExpired = this.utilsService.isExpiredTOKEN(this.utilsService.getExpirationDate(this.authData.accessToken));
    const refreshTokenExpired = this.utilsService.isExpiredTOKEN(this.utilsService.getExpirationDate(this.authData.refreshToken));
    const mobileTokenExpired = this.utilsService.isExpiredTOKEN(this.utilsService.getExpirationDate(this.authData.mobileToken));
    if (tokenExpired && refreshTokenExpired && mobileTokenExpired) {
      this.expiredSeassonAlert();
      this.validateToken();
    } else {
      this.timer();
    }
  }

  timer() {
    setTimeout(() => {
      this.controlTokenTime();
    }, 1000);
  }
  expiredSeassonAlert() {
    const msg = this.translate.instant('MESSAGES.SESSION');
    const back = this.translate.instant('MESSAGES.BACK');
    const modalParams = {
      text: msg,
      btnList: [
        {
          type: 'ghost',
          index: 1,
          title: back,
        },
      ],
    };
    this.alertService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
        componentRef.btnEvent.subscribe((resp) => {
          this.alertService.removeModal();
          location.reload();
        });
      });
      modal.onClose().subscribe();
    });
    this.amplitudeService.trackEvent('session_expired', {});
  }
  public renewSession(action) {
    this.biometricService.checkBiometricAvailable();
    const modalParams = {};
    this.aletService.showModal(RenewSessionComponent, modalParams, '', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: RenewSessionComponent) => {
        componentRef.cancelEvent.subscribe((res) => {
          this.aletService.removeModal();
        });
        componentRef.aceptEvent.subscribe((res) => {
          this.storage.get('USERNAME').then(async (val) => {
            const params = {
              email: val,
              password: res,
            };
            const isLoginCorrect = await this.login(params);
            if (isLoginCorrect) {
              this.aletService.removeModal();
              this.refreshLoginevent.emit(action);
            } else {
              componentRef.passwordError();
            }
           });
          });
          this.biometricService.getCredentialsEvent.subscribe(credentials => {
            if (credentials !== null) {
              this.storage.get('USERNAME').then(async (val) => {
                const params = {
                  email: credentials.username,
                  password: credentials.password,
                };
                const isLoginCorrect = await this.login(params);
                if (isLoginCorrect) {
                  this.aletService.removeModal();
                  this.refreshLoginevent.emit(action);
                } else {
                  componentRef.passwordError();
                }
               });
            }
          });
        });
         modal.onClose().subscribe();
      });
  }

  isSuceesLogin(slugToInvest?: string, meetingId?: any, projectId?: any, investId?: any) {
    if (slugToInvest && slugToInvest !== undefined) {
      const navigationExtras: NavigationExtras = {
        state: {
          slugProject: slugToInvest,
        },
      };
      this.storage.set('LASTPAGE', navigationExtras);
      this.navCtrl.navigateRoot([this.localize.translateRoute(`/list-of-projects`)], navigationExtras);
    } else if (projectId !== null &&  projectId !== undefined && investId !== null && investId !== undefined) {
      const navigationExtras: NavigationExtras = {
        state: {
          projectId: projectId,
          investId: investId
        },
      };
      this.navCtrl.navigateRoot([this.localize.translateRoute('/create-sign')], navigationExtras);
    } else if (meetingId) {
      const queryParams = {
        meetingId: meetingId
      };
      const navigationExtras: NavigationExtras = {
        queryParams: queryParams
      };
      this.navCtrl.navigateRoot([this.localize.translateRoute(`/meeting-detail`)], navigationExtras);
    } else {
      //this.utilsService.navigateTo('/portfolio');
      this.utilsService.navigateTo('/list-of-projects');
    }
    this.profileService.getProfile(this.userEmail).subscribe(
      (response) => {
        this.profile = response;
        this.storage.set('PROFILE', response);
        let documentType: number;
        if (response.identityDocumentDni !== null && response.identityDocumentDni !== undefined) {
          documentType = 1;
        } else if (response.identityDocumentNie !== null && response.identityDocumentNie !== undefined) {
          documentType = 2;
        } else if (response.identityDocumentPassport !== null && response.identityDocumentPassport !== undefined) {
          documentType = 3;
        }
        const wallet = {
          walletstatus: response.walletstatus,
          docType: documentType,
          refCode: response.referralCode,
        };
        this.storage.set('WALLET', wallet);
        this.checkProfileToComplete(response, true);
      },
      (error) => {
        this.storage.set('WALLET', undefined);
        this.profileService.getUserData().subscribe(resp => {
          const model = {
            username: resp.username,
            mailVerified: resp.enabled,
            profileType: resp.profileType,
            mobile: resp.mobile,
            phone: resp.mobile,
          }
          this.completeProfile(model);
        }, error => {
          console.log(error);
        });
      }
    );
  }

  getWalletType() {
    let txt;
    let btnTxt;
    let url;
    let status;
    let img;
    this.storage.get('WALLET').then((val) => {
      if (val !== null && val !== undefined) {
        status = val.walletstatus;
        switch (status) {
          case -1:
            btnTxt = this.translate.instant('WALLET_STATUS.PENDING.BTN');
            url = 'mailto:soporte@fundeen.com';
            img = 4;
            txt = this.translate.instant('WALLET_STATUS.PENDING.TEXT');
            // this.setProfileModalError(txt, btnTxt, url, true, img);
            break;
          case 1:
            btnTxt = this.translate.instant('WALLET_STATUS.PENDING.BTN');
            url = 'mailto:soporte@fundeen.com';
            img = 1;
            txt = this.translate.instant('WALLET_STATUS.INTERNAL_ERROR.TEXT');
            //this.setProfileModalError(txt, btnTxt, url, true, img);
            break;
          case 2:
            btnTxt = this.translate.instant('WALLET_STATUS.PENDING.BTN');
            url = 'mailto:soporte@fundeen.com';
            img = 1;
            txt = this.translate.instant('WALLET_STATUS.COMMUNICATION_ERROR.TEXT');
            //this.setProfileModalError(txt, btnTxt, url, true, img);
            break;
          case 3:
            this.toastService.showToast(this.translate.instant('WALLET_STATUS.CLOSED'), 'warning', true);
            break;
          case 4:
            this.toastService.showToast(this.translate.instant('WALLET_STATUS.BLOQUED'), 'warning', true);
            break;
        }
      } else {
        const env = environment.env;
        switch (env) {
          case 'dev':
            this.utilsService.navigateTo('/portfolio');
            // this.registerService.completeRegister(this.userEmail, 'ko');
            break;
          case 'test':
            this.alertToCompleteRegister('https://test.fundeen.com/es/completar-registro');
            break;
          case 'prod':
            this.alertToCompleteRegister('https://www.fundeen.com/es/completar-registro');
            break;
        }
      }
    });
  }
  setProfileModalError(txt, btnTxt, url, hasWallet, img) {
    const modalParams = {
      text: txt,
      image: img,
      btnList: [
        {
          type: 'primaryDark',
          index: 1,
          title: btnTxt,
        },
      ],
    };
    this.modalService.showModal(OveralMessageComponent, modalParams, 'Invertir').subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
        componentRef.btnEvent.subscribe((x) => {
          if (hasWallet) {
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.click();
          } else {
            window.open(url);
            this.storage.get('NAME').then((val) => {
              if (val === null || val === undefined) {
                this.modalService.removeModal();
                this.logout();
              }
            });
          }
        });
      });
      modal.onClose().subscribe();
    });
  }

  alertToCompleteRegister(url) {
    this.logout();
    const modalParams = {
      text: this.translate.instant('REGISTER.COMPLETE_REGISTER'),
      btnList: [
        {
          type: 'primaryDark',
          index: 1,
          title: this.translate.instant('card.continue'),
        }
      ],
    };
    this.alertService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
        componentRef.btnEvent.subscribe((resp) => {
          window.location.href = url;
          this.alertService.removeModal();
        });
      });
      modal.onClose().subscribe();
    });
  }

  public getWalletStatus() {
    return this.communicationService.get('apiservices/profile/v1/profiles/wallets/status', null);
  }

  checkProfileToComplete(profile, askForTerms?: boolean) {
    const steps = [];

    if (profile.profileType === 1) { // FISICA
      /*if (!profile.mailVerified || profile.mailVerified === null || profile.mailVerified === undefined) {
        steps.push(true);
      }*/
      if (!profile.mobile || profile.mobile === null || profile.mobile === undefined || profile.mobile === '') {
        steps.push(true);
      }
      // if (!profile.mobileVerified || profile.mobileVerified === null || profile.mobileVerified === undefined) {
      //   steps.push(true);
      // }
      if (profile.name === null || profile.name === undefined || profile.name === '') {
        steps.push(true);
      }
      if (profile.nationality === null || profile.nationality === undefined || profile.nationality === '') {
        steps.push(true);
      }
      if ((profile.taxCity === null || profile.taxCity === undefined || profile.taxCity === '') && (profile.taxInternationalCity === null || profile.taxInternationalCity === undefined || profile.taxInternationalCity === '')) {
        steps.push(true);
      }
      if ((!profile.identityDocumentDni || profile.identityDocumentDni === null || profile.identityDocumentDni === undefined || profile.identityDocumentDni === '')
      && (!profile.identityDocumentNie || profile.identityDocumentNie === null || profile.identityDocumentNie === undefined || profile.identityDocumentNie === '')
      && (!profile.identityDocumentPassport || profile.identityDocumentPassport === null || profile.identityDocumentPassport === undefined || profile.identityDocumentPassport === '')) {
        steps.push(true);
      }
      if ((!profile.identityDocumentFront || profile.identityDocumentFront === null || profile.identityDocumentFront === undefined || profile.identityDocumentFront === '')
      && (!profile.identityDocumentFrontNie || profile.identityDocumentFrontNie === null || profile.identityDocumentFront === undefined || profile.identityDocumentFrontNie === '')
      && (!profile.identityDocumentFrontPassport || profile.identityDocumentFrontPassport === null || profile.identityDocumentFrontPassport === undefined || profile.identityDocumentFrontPassport === '')) {
        steps.push(true);
      }/*
      if (!profile.acceptPaymentEntity || profile.acceptPaymentEntity === null || profile.acceptPaymentEntity === undefined || profile.acceptPaymentEntity === '') {
        steps.push(true);
      }*/
      if (profile.mangopayWalletId === null && (profile.walletstatus === 0 || this.profileService.checkCreationDate(profile.creationDate))) {
        steps.push(true);
      }

      if (profile.walletstatus !== null && profile.walletstatus !== undefined && profile.walletstatus > 0) {
        steps.push(true);
      }

      if (profile.walletstatus === -1) {
        let addStep = false;
        profile.files.forEach(element => {
          if ((element.field === 'ID_CARD_FRONT' || element.field === 'ID_CARD_BACK') && element.mpStatus !== null && (element.mpStatus > 3 || element.lwStatus > 2)) {
            addStep = true;
          }
        });
        if (addStep) {
          steps.push(true);
        }
      }
    } else { // JURIDICA
      /*if (!profile.mailVerified || profile.mailVerified === null || profile.mailVerified === undefined) {
        steps.push(true);
      }*/
      if (profile.mangopayWalletId === null && (profile.walletstatus === 0 || this.checkCreationDate(profile.creationDate))) {
        steps.push(true);
      }
      if (!profile.mobile || profile.mobile === null || profile.mobile === undefined) {
        steps.push(true);
      }
      // if (!profile.mobileVerified || profile.mobileVerified === null || profile.mobileVerified === undefined) {
      //   steps.push(true);
      // }
      if (profile.name === null || profile.name === undefined || profile.name === '') {
        steps.push(true);
      }
      if (profile.socialDenomination === null || profile.socialDenomination === undefined || profile.socialDenomination === '') {
        steps.push(true);
      }
      if (profile.taxAddress === null || profile.taxAddress === undefined || profile.taxAddress === '') {
        steps.push(true);
      }
      if (profile.constitutionDocument1 === null || profile.constitutionDocument1 === undefined || profile.constitutionDocument1 === '') {
        steps.push(true);
      }
      if (profile.realOwnershipDocument === null || profile.realOwnershipDocument === undefined || profile.realOwnershipDocument === '') {
        steps.push(true);
      }
      // if ((profile.enterpriseModel200 === null || profile.enterpriseModel200 === undefined || profile.enterpriseModel200 === '') && (profile.creationDate !== null && (new Date(Number(profile.creationDate.split('/')[2]), Number(profile.creationDate.split('/')[1]) - 1, Number(profile.creationDate.split('/')[0])) > new Date(2021, 10, 15)))) {
      //   steps.push(true);
      // }
      if (profile.identityDocumentPartner1 === null || profile.identityDocumentPartner1 === undefined || profile.identityDocumentPartner1 === '') {
        steps.push(true);
      }
      if (profile.nameRepresentative === null || profile.nameRepresentative === undefined || profile.nameRepresentative === '') {
        steps.push(true);
      }
      if ((profile.addressRepresentative === null || profile.addressRepresentative === undefined)
      && (profile.postalCodeRepresentative === null || profile.postalCodeRepresentative === undefined)) {
        steps.push(true);
      }
      if (profile.nationalityRepresentative === null || profile.nationalityRepresentative === undefined || profile.nationalityRepresentative === '') {
        steps.push(true);
      }
      // if (profile.addressVerifyRepresentative === null || profile.addressVerifyRepresentative === undefined || profile.addressVerifyRepresentative === '') {
      //   steps.push(true);
      // }
      if (!profile.acceptPaymentEntity || profile.acceptPaymentEntity === null || profile.acceptPaymentEntity === undefined || profile.acceptPaymentEntity === '') {
        //steps.push(true);
      }
    }

    if (steps.length >= 1) {
      this.completeProfile(profile);
    }
  }

  completeProfile(profileU?: any, params?: any, operation?: any) {
    // mirar los params
    let modalParams;
    const isMobileApp = (Capacitor.getPlatform() !== 'web') ? 'native' : undefined;
    const register = this.translate.instant('REGISTER.REGISTER');
    if (params === undefined) {
      modalParams = {
        profile: profileU,
        towns: this.profileService,
        isMobile: isMobileApp
      };
    } else {
      modalParams = params;
    }

    this.modalService.showModal(RegisterComponent, modalParams, register).subscribe((modal: any) => {
      this.modalService.setPageTitle('Fundeen - Registro');
      if(params === null || params === undefined) {
        modal.hideButtonClose(true);
      }
      modal.reduceHeight();
      modal.getChildComponent().subscribe((componentRef: RegisterComponent) => {
        componentRef.emitLoginEvent.subscribe(x => {
          this.modalService.removeModal();
        });

        componentRef.acceptLWTermsEvent.subscribe(resp => {
          this.profileService.updateProfile(resp).subscribe();
        });

        componentRef.finishProccessEvent.subscribe(resp => {
          this.finishCompleteRegister.emit();
        });

        componentRef.checkRefCodeEvent.subscribe(code => {
          this.profileService.getRefCodeValidation(code).subscribe(resp => {
            componentRef.setRefUsername(resp.referred);
          }, error => {
            componentRef.setRefUsername(undefined);
            this.toastService.showToast(this.translate.instant('ERRORS.CODE'), 'warning', true);
          });
        });

        componentRef.validateEmailEvent.subscribe(value => {
          componentRef.registerPersonSetLoading();
          this.storage.get('UTM').then((val) => {
            let utm: string
            let hubspotCookie;
            if (this.profileService.getCookie('hubspotutk') === null) {
              hubspotCookie = '';
            } else {
              hubspotCookie = this.profileService.getCookie('hubspotutk');
            }
            if (val !== null && val !== undefined) {
              utm = val;
            } else {
              utm = '';
            }
            this.recaptchaV3Service.execute('importantAction').subscribe((token) => {
              const headers = new HttpHeaders({ 'g-recaptcha-response': token });
              this.http.post(`${this.appConfigurationService.get('domain')}apiservices/user/v1/users/recaptcha`, null, {headers}).subscribe(res => {
                this.profileService.register(value.email, value.pwd, value.acceptOffers, 'fundeen', utm, hubspotCookie, value.profileType, value.referredPromotionalCode).subscribe(async () => {
                  const params = {
                    username: value.email,
                    acceptOffers: value.acceptOffers,
                    profileType: value.profileType,
                    publicWeb: false
                  }
                  this.amplitudeService.trackEvent('register_init', params);
                  componentRef.registerDone('ok');
                }, error => {
                  const msg = this.translate.instant('MESSAGES.REGISTERED_EMAIL');
                  componentRef.registerDone('ko');
                  this.toastService.showToast(msg, 'warning', true);
                });
              }, error => {
                const msg = this.translate.instant('MESSAGES.REGISTERED_EMAIL');
                componentRef.registerPersonRemoveLoading();
                componentRef.registerPersonPrev();
                this.toastService.showToast(msg, 'warning', true);
              })
            }, error => {
                const msg = this.translate.instant('MESSAGES.REGISTERED_EMAIL');
                componentRef.registerPersonRemoveLoading();
                componentRef.registerPersonPrev();
                this.toastService.showToast(msg, 'warning', true);
            });
          });
        });

        componentRef.codeEmailEvent.subscribe(resp => {
          componentRef.registerPersonSetLoading();
          this.profileService.getMailVerification(resp.code, resp.email).subscribe(() => {
            componentRef.registerPersonRemoveLoading();
          }, error => {
            const msg = this.translate.instant('MESSAGES.INCORRECT_CODE');
            componentRef.registerPersonPrev();
            componentRef.registerPersonRemoveLoading();
            this.toastService.showToast(msg, 'warning', true);
          });
        });

        componentRef.changeEmailEvent.subscribe(resp => {
          componentRef.registerPersonSetLoading();
          this.storage.get('USERNAME').then((val) => {
            const oldParams = {
              email: val,
              password: resp.password
            }
            const params = {
              email: resp.email,
              password: resp.password
            };

            this.login(oldParams).then(data => {
              if (data) {
                componentRef.registerPersonSetLoading();
                this.profileService.getMailCode(resp.email).subscribe(() => {
                  this.login(params).then(data => {
                    if (data) {
                      componentRef.registerPersonRemoveLoading();
                    } else {
                      const msg = this.translate.instant('MESSAGES.MAL_CHANGED_VALIDATE_IT');
                      // componentRef.registerPersonRemoveLoading();
                      // componentRef.registerPersonPrev();
                      this.modalService.removeModal();
                      this.toastService.showToast(msg, 'warning', true);
                    }
                  });
                }, error => {
                  const msg = this.translate.instant('MESSAGES.PASSWORD_INVALID');
                  componentRef.registerPersonRemoveLoading();
                  componentRef.registerPersonPrev();
                  this.toastService.showToast(msg, 'warning', true);
                });
              } else {
                const msg = this.translate.instant('MESSAGES.PASSWORD_INVALID');
                componentRef.registerPersonRemoveLoading();
                componentRef.registerPersonPrev();
                this.toastService.showToast(msg, 'warning', true);
              }
            }, error => {
              const msg = this.translate.instant('MESSAGES.PASSWORD_INVALID');
              componentRef.registerPersonRemoveLoading();
              componentRef.registerPersonPrev();
              this.toastService.showToast(msg, 'warning', true);
            });
          });
        });

        componentRef.updateInfoEvent.subscribe(resp => {
          componentRef.registerPersonSetLoading();
          this.profileService.updateProfile(resp).subscribe((response) => {
            componentRef.registerPersonRemoveLoading();
            this.storage.set('PROFILE', response);
          }, error => {
            const msg = this.translate.instant('MESSAGES.REGISTERED_EMAIL');
            componentRef.registerPersonRemoveLoading();
            componentRef.registerPersonPrev();
            this.toastService.showToast(msg, 'warning', true);
          });
        });

        componentRef.companyProfileGeneratedEvent.subscribe(() => {
          this.profileGeneratedEvent.emit();
        });

        componentRef.phoneNumberEvent.subscribe(number => {
          this.profileService.getPhoneCode(number).subscribe(() => {
            //componentRef.registerPersonRemoveLoading();
          }, error => {
            //componentRef.registerPersonSetLoading();
            componentRef.registerPersonPrev();
            //componentRef.registerPersonRemoveLoading();
            const msg = this.translate.instant('MESSAGES.INCORRECT_NUMBER');
            this.toastService.showToast(msg, 'warning', true);
          });
        });

        componentRef.resendCodeEmailEvent.subscribe(number => {
          let mail;
          mail = number;

          if (mail !== undefined) {
            this.profileService.getMailCode(mail).subscribe(() => {});
          } else {
            this.storage.get('USERNAME').then((val) => {
              mail = val;
              this.profileService.getMailCode(mail).subscribe(() => {});
            });
          }
        });

        componentRef.resendCodeEvent.subscribe(number => {
          this.profileService.getPhoneCode(number).subscribe(() => {
            componentRef.registerPersonRemoveLoading();
          });
        });

        componentRef.codeNumberEvent.subscribe(resp => {
          componentRef.registerPersonSetLoading();
          this.profileService.getPhoneVerification(resp.phone, resp.code).subscribe(response => {
            componentRef.registerPersonRemoveLoading();
            if (response !== true) {
              componentRef.registerPersonPrev();
              const msg = this.translate.instant('MESSAGES.INCORRECT_CODE');
              this.toastService.showToast(msg, 'warning', true);
            }
            // Tiene que llegar true
          }, error => {
            componentRef.registerPersonPrev();
            componentRef.registerPersonRemoveLoading();
            const errMsgLogin = this.translate.instant('MESSAGES.INCORRECT_CODE');
            this.toastService.showToast(errMsgLogin, 'warning', true);
          });
        });

        componentRef.generateProfilePersonEvent.subscribe(resp => {
          componentRef.registerPersonSetLoading();
          const model = resp;
          if (model.username === undefined) {
            model.username = profileU.username;
          }

          this.profileService.generateProfile(model).subscribe(response => {
            this.storage.set('NAME', model.name);
            this.storage.set('PROFILE', response);
            this.profileGeneratedEvent.emit();
            componentRef.registerPersonRemoveLoading();
            setTimeout(() => {
              this.utilsService.navigateTo('/list-of-projects');
            }, 1000);
            this.amplitudeService.trackEvent('register_profile_generated', {});
          }, error => {
            componentRef.registerPersonPrev();
            componentRef.registerPersonRemoveLoading();
          });
        });

        componentRef.updateCompanyAddressEvent.subscribe(resp => {
          const msg = this.translate.instant('MESSAGES.COMPANY_AD_ERROR');
          componentRef.registerPersonSetLoading();
          this.profileService.updateProfile(resp).subscribe((response) => {
            this.storage.set('PROFILE', response);
            componentRef.registerPersonRemoveLoading();
          }, error => {
            componentRef.registerPersonRemoveLoading();
            componentRef.registerPersonPrev();
            this.toastService.showToast(msg, 'warning', true);
          });
        });

        componentRef.updateConstitutionDocumentEvent.subscribe(resp => {
          const msg = this.translate.instant('MESSAGES.UPDATE_DOCUMENTS_ERROR');
          componentRef.registerPersonSetLoading();
          const documents = resp;
          if (documents[0] && documents[0] !== undefined) {
            this.profileService.uploadFile(documents[0]).subscribe(resp => {
              const document1 = resp.fileID;
              if (documents[1] && documents[1] !== undefined) {
                this.profileService.uploadFile(documents[1]).subscribe(resp => {
                  const document2 = resp.fileID;
                  const model = {
                    constitutionDocument1: document1,
                    constitutionDocument2: document2
                  };
                  this.profileService.updateProfile(model).subscribe(response => {
                    this.storage.set('PROFILE', response);
                    componentRef.registerPersonRemoveLoading();
                  }, error => {
                    componentRef.registerPersonRemoveLoading();
                    componentRef.registerPersonPrev();
                    this.toastService.showToast(msg, 'warning', true);
                  });
                }, error => {
                  componentRef.registerPersonRemoveLoading();
                  componentRef.registerPersonPrev();
                  const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                  this.toastService.showToast(specificMsg, 'warning', true);
                });
              } else {
                const model = {
                  constitutionDocument1: document1
                }
                this.profileService.updateProfile(model).subscribe((response) => {
                  this.storage.set('PROFILE', response);
                  componentRef.registerPersonRemoveLoading();
                }, error => {
                  componentRef.registerPersonRemoveLoading();
                  componentRef.registerPersonPrev();
                  this.toastService.showToast(msg, 'warning', true);
                });
              }
            }, error => {
              componentRef.registerPersonRemoveLoading();
              componentRef.registerPersonPrev();
              const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
              this.toastService.showToast(specificMsg, 'warning', true);
            });
          }
        });

        componentRef.updateRealOwnershipDocumentEvent.subscribe(resp => {
          const msg = this.translate.instant('MESSAGES.UPDATE_DOCUMENTS_ERROR');
          componentRef.registerPersonSetLoading();
          const documents = resp;
          if (documents[0] && documents[0] !== undefined) {
            this.profileService.uploadFile(documents[0]).subscribe(resp => {
              const document = resp.fileID;
              const model = {
                realOwnershipDocument: document
              }
              this.profileService.updateProfile(model).subscribe((response) => {
                this.storage.set('PROFILE', response);
                componentRef.registerPersonRemoveLoading();
              }, error => {
                componentRef.registerPersonRemoveLoading();
                componentRef.registerPersonPrev();
                this.toastService.showToast(msg, 'warning', true);
              });
            }, error => {
              componentRef.registerPersonRemoveLoading();
              componentRef.registerPersonPrev();
              const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
              this.toastService.showToast(specificMsg, 'warning', true);
            });
          }
        });

        // componentRef.updateModel200DocumentEvent.subscribe(resp => {
        //   const msg = this.translate.instant('MESSAGES.UPDATE_DOCUMENTS_ERROR');
        //   componentRef.registerPersonSetLoading();
        //   const documents = resp;
        //   if (documents[0] && documents[0] !== undefined) {
        //     this.profileService.uploadFile(documents[0]).subscribe(resp => {
        //       const document = resp.fileID;
        //       const model = {
        //         enterpriseModel200: document
        //       }
        //       this.profileService.updateProfile(model).subscribe((response) => {
        //         this.storage.set('PROFILE', response);
        //         componentRef.registerPersonRemoveLoading();
        //       }, error => {
        //         componentRef.registerPersonRemoveLoading();
        //         componentRef.registerPersonPrev();
        //         this.toastService.showToast(msg, 'warning', true);
        //       });
        //     }, error => {
        //       componentRef.registerPersonRemoveLoading();
        //       componentRef.registerPersonPrev();
        //       const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
        //       this.toastService.showToast(specificMsg, 'warning', true);
        //     });
        //   }
        // });

        componentRef.updateIdentityPartnersDocumentsEvent.subscribe(resp => {
          const msg = this.translate.instant('MESSAGES.UPDATE_DOCUMENTS_ERROR');
          componentRef.registerPersonSetLoading();
          const documents = resp;
          if (documents[0] && documents[0] !== undefined) {
            this.profileService.uploadFile(documents[0]).subscribe(resp => {
              const document1 = resp.fileID;
              if (documents[1] && documents[1] !== undefined) {
                this.profileService.uploadFile(documents[1]).subscribe(resp => {
                  const document2 = resp.fileID;
                  if (documents[2] && documents[2] !== undefined) {
                    this.profileService.uploadFile(documents[2]).subscribe(resp => {
                      const document3 = resp.fileID;
                      if (documents[3] && documents[3] !== undefined) {
                        this.profileService.uploadFile(documents[3]).subscribe(resp => {
                          const document4 = resp.fileID;
                          const model = {
                            identityDocumentPartner1: document1,
                            identityDocumentPartner2: document2,
                            identityDocumentPartner3: document3,
                            identityDocumentPartner4: document4
                          };
                          this.profileService.updateProfile(model).subscribe((response) => {
                            this.storage.set('PROFILE', response);
                            componentRef.registerPersonRemoveLoading();
                          }, error => {
                            componentRef.registerPersonRemoveLoading();
                            componentRef.registerPersonPrev();
                            this.toastService.showToast(msg, 'warning', true);
                          });
                        }, error => {
                          componentRef.registerPersonRemoveLoading();
                          componentRef.registerPersonPrev();
                          const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                          this.toastService.showToast(specificMsg, 'warning', true);
                        });
                      } else {
                        const model = {
                          identityDocumentPartner1: document1,
                          identityDocumentPartner2: document2,
                          identityDocumentPartner3: document3
                        };
                        this.profileService.updateProfile(model).subscribe(resp => {
                          this.storage.set('PROFILE', resp);
                          componentRef.registerPersonRemoveLoading();
                        }, error => {
                          componentRef.registerPersonRemoveLoading();
                          componentRef.registerPersonPrev();
                          this.toastService.showToast(msg, 'warning', true);
                        });
                      }
                    }, error => {
                      componentRef.registerPersonRemoveLoading();
                      componentRef.registerPersonPrev();
                      const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                      this.toastService.showToast(specificMsg, 'warning', true);
                    });
                  } else {
                    const model = {
                      identityDocumentPartner1: document1,
                      identityDocumentPartner2: document2
                    };
                    this.profileService.updateProfile(model).subscribe((response) => {
                      this.storage.set('PROFILE', response);
                      componentRef.registerPersonRemoveLoading();
                    }, error => {
                      componentRef.registerPersonRemoveLoading();
                      componentRef.registerPersonPrev();
                      this.toastService.showToast(msg, 'warning', true);
                    });
                  }
                }, error => {
                  componentRef.registerPersonRemoveLoading();
                  componentRef.registerPersonPrev();
                  const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                  this.toastService.showToast(specificMsg, 'warning', true);
                });
              } else {
                const model = {
                  identityDocumentPartner1: document1
                }
                this.profileService.updateProfile(model).subscribe((response) => {
                  this.storage.set('PROFILE', response);
                  componentRef.registerPersonRemoveLoading();
                }, error => {
                  componentRef.registerPersonRemoveLoading();
                  componentRef.registerPersonPrev();
                  this.toastService.showToast(msg, 'warning', true);
                });
              }
            }, error => {
              componentRef.registerPersonRemoveLoading();
              componentRef.registerPersonPrev();
              const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
              this.toastService.showToast(specificMsg, 'warning', true);
            });
          }
        });

        componentRef.emitPersonalDocsEvent.subscribe(resp => {
          setTimeout(() =>  {
            componentRef.registerPersonSetLoading();
            this.profileService.updateProfile(resp).subscribe((response) => {
              this.storage.set('PROFILE', response);
              componentRef.registerPersonRemoveLoading();
              //componentRef.registerDocumentRemoveLoading();
              // Poner el paso dos
            }, error => {
              const msg = this.translate.instant('MESSAGES.DOCUMENT_EXIST_ERROR');
              componentRef.registerPersonRemoveLoading()
              componentRef.registerPersonPrev();
              this.toastService.showToast(msg, 'warning', true);
            });
          }, 10);
        });

        componentRef.updateRLDataEvent.subscribe(resp => {
          componentRef.registerPersonSetLoading();
          this.profileService.updateProfile(resp).subscribe(response => {
            this.storage.set('PROFILE', response);
            componentRef.registerPersonRemoveLoading();
          }, (error) => {
            const msg = this.translate.instant('MESSAGES.SAVE_PERSONALDATA_ERROR');
            componentRef.registerPersonRemoveLoading();
            componentRef.registerPersonPrev();
            this.toastService.showToast(msg, 'warning', true);
          });
        });

        componentRef.updateRLIdentityDocumentEvent.subscribe(resp => {
          const msg = this.translate.instant('MESSAGES.LEGAL_REP_ID_ERROR');
          switch (resp.identityDocumentTypeRepresentative) {
            case 1: // Si es DNI
              componentRef.registerPersonSetLoading();
              const dniDocuments = resp;
              if (dniDocuments.identityDocumentFrontRepresentative && dniDocuments.identityDocumentFrontRepresentative !== undefined) {
                this.profileService.uploadFile(dniDocuments.identityDocumentFrontRepresentative).subscribe(resp => {
                  const dniFront = resp.fileID;
                  if (dniDocuments.identityDocumentBackRepresentative && dniDocuments.identityDocumentBackRepresentative !== undefined) {
                    this.profileService.uploadFile(dniDocuments.identityDocumentBackRepresentative).subscribe(resp => {
                      const dniBack = resp.fileID;
                      const model = {
                        identityDocumentFrontRepresentative: dniFront,
                        identityDocumentBackRepresentative: dniBack
                      };
                      this.profileService.updateProfile(model).subscribe(resp => {
                        this.storage.set('PROFILE', resp);
                        componentRef.registerPersonRemoveLoading();
                      }, error => {
                        componentRef.registerPersonRemoveLoading();
                        componentRef.registerPersonPrev();
                        this.toastService.showToast(msg, 'warning', true);
                      });
                    }, (error) => {
                      componentRef.registerPersonRemoveLoading();
                      componentRef.registerPersonPrev();
                      const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                      this.toastService.showToast(specificMsg, 'warning', true);
                    });
                  } else {
                    const model = {
                      identityDocumentFrontRepresentative: dniFront
                    };
                    this.profileService.updateProfile(model).subscribe(resp => {
                      this.storage.set('PROFILE', resp);
                      componentRef.registerPersonRemoveLoading();
                    }, (error) => {
                      componentRef.registerPersonRemoveLoading();
                      componentRef.registerPersonPrev();
                      this.toastService.showToast(msg, 'warning', true);
                    });
                  }
                }, (error) => {
                  componentRef.registerPersonRemoveLoading();
                  componentRef.registerPersonPrev();
                  const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                  this.toastService.showToast(specificMsg, 'warning', true);
                });
              }
              break;
              case 2: // Si es NIE
                componentRef.registerPersonSetLoading();
                const nieDocuments = resp;
                if (nieDocuments.identityDocumentFrontNieRepresentative && nieDocuments.identityDocumentFrontNieRepresentative !== undefined) {
                  this.profileService.uploadFile(nieDocuments.identityDocumentFrontNieRepresentative).subscribe(resp => {
                    const nieFront = resp.fileID;
                    if (nieDocuments.identityDocumentBackNieRepresentative && nieDocuments.identityDocumentBackNieRepresentative !== undefined) {
                      this.profileService.uploadFile(nieDocuments.identityDocumentBackNieRepresentative).subscribe(resp => {
                        const nieBack = resp.fileID;
                        const model = {
                          identityDocumentFrontNieRepresentative: nieFront,
                          identityDocumentBackNieRepresentative: nieBack
                        };
                        this.profileService.updateProfile(model).subscribe(resp => {
                          this.storage.set('PROFILE', resp);
                          componentRef.registerPersonRemoveLoading();
                        }, error => {
                          componentRef.registerPersonRemoveLoading();
                          componentRef.registerPersonPrev();
                          this.toastService.showToast(msg, 'warning', true);
                        });
                      }, (error) => {
                        componentRef.registerPersonRemoveLoading();
                        componentRef.registerPersonPrev();
                        const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                        this.toastService.showToast(specificMsg, 'warning', true);
                      });
                    } else {
                      const model = {
                        identityDocumentFrontNieRepresentative: nieFront
                      };
                      this.profileService.updateProfile(model).subscribe(resp => {
                        this.storage.set('PROFILE', resp);
                        componentRef.registerPersonRemoveLoading();
                      }, (error) => {
                        componentRef.registerPersonRemoveLoading();
                        componentRef.registerPersonPrev();
                        this.toastService.showToast(msg, 'warning', true);
                      });
                    }
                  }, (error) => {
                    componentRef.registerPersonRemoveLoading();
                    componentRef.registerPersonPrev();
                    const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                    this.toastService.showToast(specificMsg, 'warning', true);
                  });
                }
                break;
            case 3: // Si es pasaporte
              componentRef.registerPersonSetLoading();
              const passportDocuments = resp;
              if (passportDocuments.identityDocumentFrontPassportRepresentative && passportDocuments.identityDocumentFrontPassportRepresentative !== undefined) {
                this.profileService.uploadFile(passportDocuments.identityDocumentFrontPassportRepresentative).subscribe(resp => {
                  const passportFront = resp.fileID;
                  const model = {
                    identityDocumentFrontPassportRepresentative: passportFront
                  };
                  this.profileService.updateProfile(model).subscribe((resp) => {
                    this.storage.set('PROFILE', resp);
                    componentRef.registerPersonRemoveLoading();
                  }, error => {
                    componentRef.registerPersonRemoveLoading();
                    componentRef.registerPersonPrev();
                    this.toastService.showToast(msg, 'warning', true);
                  });
                }, error => {
                  componentRef.registerPersonRemoveLoading();
                  componentRef.registerPersonPrev();
                  const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                  this.toastService.showToast(specificMsg, 'warning', true);
                });
              }
              break;
          }
        });

        // componentRef.updateProofAddressDocumentEvent.subscribe(resp => {
        //   const msg = this.translate.instant('MESSAGES.LEGAL_REP_HOME_ERROR');
        //   componentRef.registerPersonSetLoading();
        //   if (resp.addressVerifyRepresentative && resp.addressVerifyRepresentative !== undefined) {
        //     this.profileService.uploadFile(resp.addressVerifyRepresentative).subscribe(resp => {
        //       const proofAddress = resp.fileID;
        //       const model = {
        //         addressVerifyRepresentative: proofAddress
        //       };
        //       this.profileService.updateProfile(model).subscribe(resp => {
        //         this.storage.set('PROFILE', resp);
        //         componentRef.registerPersonRemoveLoading();
        //       }, (error) => {
        //         componentRef.registerPersonRemoveLoading();
        //         componentRef.registerPersonPrev();
        //         this.toastService.showToast(msg, 'warning', true);
        //       });
        //     }, error => {
        //       componentRef.registerPersonRemoveLoading();
        //       componentRef.registerPersonPrev();
        //       const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
        //       this.toastService.showToast(specificMsg, 'warning', true);
        //     });
        //   }
        // });

        componentRef.updateAcreditationCompanyChecksEvent.subscribe(resp => {
          componentRef.registerPersonSetLoading();
          this.profileService.updateProfile(resp).subscribe(resp => {
            this.storage.set('PROFILE', resp);
            componentRef.registerPersonRemoveLoading();
          }, (error) => {
            const msg = this.translate.instant('MESSAGES.SAVE_CONDITIONS_ERROR');
            componentRef.registerPersonRemoveLoading();
            componentRef.registerPersonPrev();
            this.toastService.showToast(msg, 'warning', true);
          });
        });

        componentRef.updateAcreditationCompanyDocEvent.subscribe(resp => {
          const msg = this.translate.instant('MESSAGES.ACCREDITATION_DOC_ERROR');
          const acreditationDoc = resp;
          this.profileService.uploadFile(resp.file).subscribe(resp => {
            let model;
            switch (acreditationDoc.type) {
              case 1:
                model = {
                  acreditationVerificationBalanceSheet: resp.fileID
                };
                break;
              case 2:
                model = {
                  acreditationVerificationProfitLosses: resp.fileID
                };
                break;
              case 3:
                model = {
                  acreditationVerificationFinancialAdvice: resp.fileID
                };
                break;
            }
            this.profileService.updateProfile(model).subscribe((resp) => {
              this.storage.set('PROFILE', resp);
              componentRef.registerPersonRemoveLoading();
            }, error => {
              componentRef.registerPersonRemoveLoading();
            });
            componentRef.registerPersonRemoveLoading();
          }, (error) => {
            componentRef.registerPersonRemoveLoading();
            componentRef.registerPersonPrev();
            const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
            this.toastService.showToast(specificMsg, 'warning', true);
          });
        });

        componentRef.emitDocumentntationEvent.subscribe(resp => {
          componentRef.registerPersonFileLoading(true);
          const documents = resp;
          const msg = this.translate.instant('REGISTER.MY_PROFILE.UPLOAD_ERROR');

          if (documents.frontDocument && documents.frontDocument!== undefined) {
            this.profileService.uploadFile(documents.frontDocument).subscribe(resp => {
              const documentFront = resp.fileID;
              if (documents.backDocument && documents.backDocument!== undefined) {
                this.profileService.uploadFile(documents.backDocument).subscribe(resp => {
                  const documentBack = resp.fileID;
                  const model = {
                    identityDocumentFront: documentFront,
                    identityDocumentBack: documentBack
                  }
                  this.profileService.updateProfile(model).subscribe((resp) => {
                    this.storage.set('PROFILE', resp);
                    //this.reloadPage();
                    componentRef.registerPersonFileLoading(false);
                    //this.modalService.removeModal();
                  }, error => {
                    componentRef.registerPersonFileLoading(false, true);
                    this.toastService.showToast(msg, 'warning', true);
                  });
                }, error => {
                  componentRef.registerPersonFileLoading(false, true);
                  const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                  this.toastService.showToast(specificMsg, 'warning', true);
                });
              } else {
                const model = {
                  identityDocumentFront: documentFront
                }
                this.profileService.updateProfile(model).subscribe((resp) => {
                  this.storage.set('PROFILE', resp);
                  //this.reloadPage();
                  componentRef.registerPersonFileLoading(false);
                  //this.modalService.removeModal();
                }, error => {
                  componentRef.registerPersonFileLoading(false, true);
                  this.toastService.showToast(msg, 'warning', true);
                });
              }
            }, error => {
              componentRef.registerPersonFileLoading(false, true);
              const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
              this.toastService.showToast(specificMsg, 'warning', true);
            });
          } else if(documents.frontNieDocument && documents.frontNieDocument!== undefined) {
            this.profileService.uploadFile(documents.frontNieDocument).subscribe(resp => {
              const documentFront = resp.fileID;
              if (documents.backNieDocument && documents.backNieDocument!== undefined) {
                this.profileService.uploadFile(documents.backNieDocument).subscribe(resp => {
                  const documentBack = resp.fileID;
                  if (documents.frontPassportDocument && documents.frontPassportDocument !== undefined) {
                    this.profileService.uploadFile(documents.frontPassportDocument).subscribe(file => {
                      const documentPassport = file.fileID;
                      const model = {
                        identityDocumentFrontNie: documentFront,
                        identityDocumentBackNie: documentBack,
                        identityDocumentFrontPassport: documentPassport
                      }
                      this.profileService.updateProfile(model).subscribe((resp) => {
                        this.storage.set('PROFILE', resp);
                        componentRef.registerPersonFileLoading(false);
                      }, error => {
                        componentRef.registerPersonFileLoading(false, true);
                        this.toastService.showToast(msg, 'warning', true);
                      });
                    }, error => {
                      componentRef.registerPersonFileLoading(false, true);
                      const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                      this.toastService.showToast(specificMsg, 'warning', true);
                    });
                  } else {
                    const model = {
                      identityDocumentFrontNie: documentFront,
                      identityDocumentBackNie: documentBack
                    }
                    this.profileService.updateProfile(model).subscribe((resp) => {
                      this.storage.set('PROFILE', resp);
                      componentRef.registerPersonFileLoading(false);
                    }, error => {
                      componentRef.registerPersonFileLoading(false, true);
                      this.toastService.showToast(msg, 'warning', true);
                    });
                  }
                }, error => {
                  const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                  this.toastService.showToast(specificMsg, 'warning', true);
                  componentRef.registerPersonFileLoading(false, true);
                });
              } else {
                if (documents.frontPassportDocument && documents.frontPassportDocument !== undefined) {
                  this.profileService.uploadFile(documents.frontPassportDocument).subscribe(file => {
                    const documentPassport = file.fileID;
                    const model = {
                      identityDocumentFrontNie: documentFront,
                      identityDocumentFrontPassport: documentPassport
                    }
                    this.profileService.updateProfile(model).subscribe((resp) => {
                      this.storage.set('PROFILE', resp);
                      componentRef.registerPersonFileLoading(false);
                      }, error => {
                        componentRef.registerPersonFileLoading(false, true);
                        this.toastService.showToast(msg, 'warning', true);
                      });
                    }, error => {
                      componentRef.registerPersonFileLoading(false, true);
                      const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
                      this.toastService.showToast(specificMsg, 'warning', true);
                    });
                } else {
                  const model = {
                    identityDocumentFrontNie: documentFront
                  }
                  this.profileService.updateProfile(model).subscribe((resp) => {
                    this.storage.set('PROFILE', resp);
                    componentRef.registerPersonFileLoading(false);
                  }, error => {
                    componentRef.registerPersonFileLoading(false, true);
                    this.toastService.showToast(msg, 'warning', true);
                  });
                }
              }
            }, error => {
              componentRef.registerPersonFileLoading(false, true);
              const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
              this.toastService.showToast(specificMsg, 'warning', true);
            });
          } else if (documents.frontPassportDocument && documents.frontPassportDocument !== undefined) {
            this.profileService.uploadFile(documents.frontPassportDocument).subscribe(file => {
              const documentPassport = file.fileID;
              const model = {
                identityDocumentFrontPassport: documentPassport
              }
              this.profileService.updateProfile(model).subscribe((resp) => {
                this.storage.set('PROFILE', resp);
                componentRef.registerPersonFileLoading(false);
                }, error => {
                  componentRef.registerPersonFileLoading(false, true);
                  this.toastService.showToast(msg, 'warning', true);
                });
            }, error => {
              componentRef.registerPersonFileLoading(false, true);
              const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
              this.toastService.showToast(specificMsg, 'warning', true);
            });
          }
        });

        componentRef.updateDocNumberEvent.subscribe((resp) => {
          componentRef.registerPersonFileLoading(true);
          this.profileService.updateProfile(resp).subscribe(response => {
            this.storage.set('PROFILE', response);
            componentRef.registerPersonFileLoading(false);
          }, error => {
            componentRef.registerPersonFileLoading(false, true);
            this.toastService.showToast(this.translate.instant('REGISTER.MY_PROFILE.DOCUMENT_DUPLICATED'), 'warning', true);
          });
        });
        // for user accredited
        componentRef.emitAccreditationChecksEvent.subscribe(resp => {

          componentRef.accreditedPersonSetLoading();
          const model = {
            accountAcreditation50kIncomings: resp.first,
            accountAcreditation100kWealth: resp.second,
            accountAcreditationProfessionalAdvice: resp.third
          };
          this.profileService.updateProfile(model).subscribe((resp) => {
            this.storage.set('PROFILE', resp);
            componentRef.accreditedPersonRemoveLoading();
          }, error => {
            componentRef.accreditedPersonRemoveLoading();
          });
        });

        componentRef.emitBankDocument.subscribe(resp => {

          componentRef.accreditedPersonSetLoading();
          this.profileService.uploadFile(resp).subscribe(response => {
            componentRef.accreditedPersonRemoveLoading();
            const model = {
              acreditationVerificationBankInfo: response.fileID
            };
            this.profileService.updateProfile(model).subscribe((response) => {
              this.storage.set('PROFILE', response);
              componentRef.accreditedPersonRemoveLoading();
            }, error => {
              componentRef.accreditedPersonRemoveLoading();
            });
          }, error => {
            const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : this.translate.instant('LOCAL_INNVEST.DYNAMIC.FILE_ERROR');
            this.toastService.showToast(specificMsg, 'warning', true);
          });
        });

        // lw terms
        componentRef.acceptLwTermsEvent.subscribe(resp => {
          modal.changeOpenStatus(false);
          if (resp.profileType === 1) {
            componentRef.accreditedPersonSetLoading();
          } else {
            componentRef.registerPersonSetLoading();
          }
          this.profileService.updateProfile(resp).subscribe((response) => {
            this.storage.set('PROFILE', response);
            if (resp.profileType === 1) {
              componentRef.accreditedPersonRemoveLoading();
            } else {
              componentRef.registerPersonRemoveLoading();
            }
          }, error => {
            if (resp.profileType === 1) {
              componentRef.accreditedPersonRemoveLoading();
            } else {
              componentRef.registerPersonRemoveLoading();
            }
          });
        });

        componentRef.finishProccessEvent.subscribe(() => {
          modal.changeOpenStatus(false);
          this.modalService.removeModal();
        });

        componentRef.continueInvestEvent.subscribe(() => {
          if (params.profile.walletstatus === 0) {
            if (operation === 'invest') {
              this.modalService.removeModal();
              this.readyToInvestEvent.emit();
            } else if (operation === 'bid') {
              this.modalService.removeModal();
              this.readyToBid.emit();
            }
          }
        });

        componentRef.emitFullAddressEvent.subscribe(value => {
          componentRef.registerPersonSetLoading();
          let interRegion;
          let internalCity;
          let region;
          let model;
          let countryC;

          if (value.region !== null && value.region !== undefined && value.region !== '') {
            region = value.region;
          } else {
            if (
              modalParams !== null
              && modalParams !== undefined
              && modalParams.profile !== null
              && modalParams.profile !== undefined
              && modalParams.profile.taxRegion !== null
              && modalParams.profile.taxRegion !== undefined
              ) {
                region = modalParams.profile.taxRegion;
              } else {
                region = null;
              }
          }
          if (value.internationalRegion !== null && value.internationalRegion !== undefined) {
            interRegion = value.internationalRegion;
            internalCity = value.city;
          } else {
            interRegion = '';
            internalCity = '';
          }

          if (
            modalParams.profile !== null
            && modalParams.profile !== undefined
            && Number(modalParams.profile.taxCountry) !== null
            && Number(modalParams.profile.taxCountry) !== undefined
            ) {
              countryC = Number(modalParams.profile.taxCountry);
            } else if (value.taxC !== null && value.taxC !== undefined) {
              countryC = Number(value.taxC);
            } else if (value.internationalRegion !== '' && value.internationalRegion !== null && value.internationalRegion !== undefined) {
              countryC = 0;
            } else {
              countryC = 108
            }

          if (countryC === 108) {
            model = {
              taxAddress: value.address,
              taxPostalCode: value.cp.toString(),
              taxRegion: region,
              taxCity: value.city
            }
          } else {
            model = {
              taxAddress: value.address,
              taxPostalCode: value.cp.toString(),
              taxRegion: region,
              taxInternationalRegion: interRegion,
              taxInternationalCity: internalCity
            }
          }

          this.profileService.updateProfile(model).subscribe((response) => {
            this.storage.set('PROFILE', response);
            componentRef.registerPersonRemoveLoading();
          }, error => {
            componentRef.registerPersonRemoveLoading();
            componentRef.registerPersonPrev();
          });
        });

        componentRef.getSignaturitEvent.subscribe(() => {
          this.profileService.getAccreditUrl().subscribe(url => {
            componentRef.setAccreditSignaturitUrl(url);
          });
        });
      });
      modal.onClose().subscribe();
    });
  }

  checkCreationDate(date) {
    const dt = `${date.substring(6, 10)}-${date.substring(3, 5)}-${date.substring(0, 2)}`;
    const isBefore = moment('2022-06-28').diff(dt);
    if (isBefore > 0) {
      return true;
    }
    return false;
  }

  profileGeneratedByTaxDown(name, profile) {
    this.storage.set('NAME', name);
    this.storage.set('PROFILE', profile);
    this.profileGeneratedEvent.emit();
    setTimeout(() => {
      this.utilsService.navigateTo('/list-of-projects');
    }, 1000);
  }
}
