import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../../services/utils.service';
import { SidemenuService } from '../../sidemenu/sidemenu.service';
import { NewButtonComponent } from '../new-button/new-button.component';
import { NewDetailComponent } from '../new-detail/new-detail.component';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Capacitor } from '@capacitor/core';
import * as momentNs from 'moment';
const moment = momentNs;

@Component({
  selector: 'fun-new-card',
  templateUrl: './new-card.component.html',
  styleUrls: ['./new-card.component.scss'],
})
export class NewCardComponent implements OnInit {

  @Input('project') project;
  @Input('size') size;
  @Input('cardType') cardType = 'generic';
  @Input('featured') featured = false;
  @Input() isFlexibleCompensation = false;
  @Input() btnTitle = 'Ver proyecto';
  @Input() hasTitle = true;
  @Output() public viewProjectEvent = new EventEmitter<any>();
  @ViewChild('btnCard', { static: false }) btnCard: NewButtonComponent;
  btnType: string;
  btnSize: string;
  opened: boolean;
  orientation: string;

  itemStyles = {
    'xl': 'gap-16 rounded-3xl p-16 md:rounded-[40px] md:p-24 shadow-xl :shhoveradow-l focus:shadow-xl md:shadow-2xl md:hover:shadow-xl md:focus:shadow-2xl',
    'l': ' gap-8 rounded-3xl md:rounded-[32px] p-12 md:p-16 shadow-l md:shadow-xl hover:shadow-m md:hover:shadow-l focus:shadow-l md:focus:shadow-xl',
    'm': ' gap-8 rounded-3xl p-12 shadow-l hover:shadow-m focus:shadow-l',
    'vertical': 'flex-col',
    'horizontal': 'flex-col md:flex-row md:flex-row-reverse',
    'xl-vertical': 'max-w-[416px] md:max-w-[528px]',
    'l-vertical': 'max-w-[416px]',
    'm-vertical': 'max-w-[306px]',
    'xl-horizontal': 'max-w-[780px] md:max-w-[920px]',
    'l-horizontal': 'max-w-[780px]',
    'm-horizontal': 'max-w-[650px]',
  };

  contentStyles = {
    'xl': 'gap-32 p-24',
    'l': 'gap-16 py-8 px-16 md:p-16',
    'm': 'gap-16 py-8 px-16'
  };

  titleStyles = {
    'xl': 'h-[70px] font-sansMedium text-xl md:h-[84px] md:font-sans md:text-h600',
    'l': 'h-[70px] md:h-[84px] font-sansMedium text-xl md:text-2xl',
    'm': 'h-[70px] font-sansMedium text-xl'
  };

  descriptionStyles = {
    'xl': 'text-l md:text-xl',
    'l': 'text-m md:text-l',
    'm': 'text-m'
  };

  listStyles = {
    'xl': 'gap-32 children:pb-16 children:text-m children:before:text-s md:children:text-2xl md:children:before:text-l',
    'l': 'gap-[10px] md:gap-[22px] children:pb-16 children:text-l md:children:text-xl children:before:text-m md:children:before:text-m',
    'm': 'gap-[10px] md:gap-[22px] children:pb-16 children:text-l children:before:text-m'
  };

  imagestyles = {
    'xl': 'rounded-[16px] md:rounded-[32px] h-[146px]',
    'l': 'rounded-2xl md:rounded-3xl h-[112px] md:h-[146px]',
    'm': 'rounded-2xl h-[112px]',
    'xl-vertical': 'h-[146px]',
    'l-vertical': 'h-[146px]',
    'm-vertical': 'h-[112px]',
    'xl-horizontal': 'h-[146px] md:h-[inherit]',
    'l-horizontal': 'h-[146px] md:h-[inherit]',
    'm-horizontal': 'h-[112px] md:h-[inherit]',
  }

  itemClass: string;
  itemStl: string;
  borderStyles: string;
  titleClass: string;
  contentClass: string;
  descriptionClass: string;
  listClass: string;
  imageClass: string;
  imgStyle: string;
  loaded = false;
  modalOpened = false;

  public years;
  public months;
  public offers = [];
  public offerNumber: any;
  public mkpBtntitle: string;

  constructor(
    private utils: UtilsService,
    private translate: TranslateService,
    private sidemenuService: SidemenuService,
    private modalCtrl: ModalController,
    public chdRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.orientation = (this.featured) ? 'horizontal': 'vertical';
    const minW = this.orientation === 'horizontal' ? ' min-w-[264px] md:min-w-[342px] ' : ' min-w-[264px] ';
    registerLocaleData(es);
    this.opened = false;
    this.project.description = this.getLocaleDescription(this.project.locale);
    if (this.project.description === undefined) {
       this.project.description = 'Proximamente...';
    }
    let borderTypeStyles;
    this.itemStl = ((this.project.status >= 2 && this.cardType === 'generic') || this.cardType !== 'generic') ? `z-20 bg-white flex flex-col transition-all duration-500 hover:cursor-pointer ${this.itemStyles[this.size]}` : `z-20 bg-white flex flex-col ${this.itemStyles[this.size]}`;
    if (this.utils.isSafariBrowser() || Capacitor.getPlatform() === 'ios') {
      borderTypeStyles = this.isFlexibleCompensation ? 'border-2 focus:border-2 border-[#0f5e42]/[.25] focus:border-[#0f5e42] hover:border-[#0f5e42] hover:border-2' : 'border-2 focus:border-2 border-orange-100/[.25] focus:border-orange-100 hover:border-orange-100 hover:border-2';
      this.borderStyles = ((this.project.status >= 2 && this.cardType === 'generic') || this.cardType !== 'generic') ? borderTypeStyles : `border-2 border-eggplant-100/[.25]`;
    } else {
      borderTypeStyles = this.isFlexibleCompensation ? '!outline outline-1 focus:!outline-2 outline-[#0f5e42]/[.25] focus:!outline-[#0f5e42] hover:outline-[#0f5e42] hover:outline-2' : '!outline outline-1 focus:!outline-2 outline-orange-100/[.25] focus:!outline-orange-100 hover:outline-orange-100 hover:outline-2';
      this.borderStyles = ((this.project.status >= 2 && this.cardType === 'generic') || this.cardType !== 'generic') ? borderTypeStyles : "!outline outline-1 focus:!outline-2 outline-eggplant-100/[.10] focus:!outline-eggplant-100/[.10]";
    }
    this.getItemStyles();
    const titleHoverBase = this.isFlexibleCompensation ? 'hover:text-[#0f5e42]' : 'hover:text-orange-100';
    if (this.hasTitle) {
      this.descriptionClass = !this.isFlexibleCompensation ? `font-sans line-clamp-3 text-eggplant-100/[.25] h-[76px] ${this.descriptionStyles[this.size]}` : `font-sans line-clamp-3 text-eggplant-100/[.55] h-[106px] text-xl`;
      this.titleClass = ((this.project.status >= 2 && this.cardType === 'generic') || this.cardType !== 'generic') ? `text-left line-clamp-2 text-eggplant-80 ${titleHoverBase} ${this.titleStyles[this.size]}` : `text-left line-clamp-2 text-eggplant-100/[0.2] ${this.titleStyles[this.size]}`;
    } else {
      this.descriptionClass = !this.isFlexibleCompensation ? `font-sans text-eggplant-100/[.25] h-[320px] md:h-[280px] ${this.descriptionStyles[this.size]}` : `font-sans text-eggplant-100/[.55] h-[320px] md:h-[280px] text-xl`;
      this.titleClass = 'hidden';
    }
    this.contentClass = `text-left flex flex-col ${this.contentStyles[this.size]}`;
    if ((this.project.status >= 2 && this.cardType === 'generic') || this.cardType !== 'generic') {
      this.listClass = `flex children:before:absolute children:before:bottom-0 children:before:font-sans children:text-eggplant-100 children:before:text-eggplant-100/[.25] ${this.listStyles[this.size]}`;
    } else {
      this.listClass = `flex children:before:absolute children:before:bottom-0 children:before:font-sans children:text-eggplant-100/[0.2] children:before:text-eggplant-100/[0.1] ${this.listStyles[this.size]}`;
    }

    if ((this.project.status >= 2 && this.cardType === 'generic') || this.cardType !== 'generic') {
      this.imageClass = `relative w-full ${minW} ${this.imagestyles[this.size]} ${this.imagestyles[this.size + '-' + this.orientation]}`;
    } else {
      this.imageClass = `relative w-full ${this.imagestyles[this.size]} opacity-20 ${this.imagestyles[this.size + '-' + this.orientation]}`;
    }

    this.imgStyle = `backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: url(${this.project['imageCard']})`;
    this.btnType = this.isFlexibleCompensation ? 'ricEnergyLight' : 'secondaryDark';
    this.btnSize = 'm';
    if (!this.project.imageCard || this.project.imageCard === undefined ) {
      this.project.imageCard = '../../../../assets/images/img/project-default.webp';
    }
    if (this.cardType === 'portfolio') {
      this.project.earned = this.utils.roundNumber(this.project.earned);
      this.project.co2 = this.utils.roundNumber(this.project.co2);
    }
    if (this.cardType === 'marketplace') {
      const startDate = moment(new Date(), 'YYYY/MM/DD');
      const endDate = moment(this.project.end_util_date, 'YYYY/MM/DD');

      this.years = endDate.diff(startDate, 'years');
      this.months = endDate.diff(startDate, 'months') - (this.years * 12);
      this.controlOffersnumber();
    }
    this.loaded = true;
    this.chdRef.detectChanges();
  }

  toggleHoverCard(action) {
    if (((this.project.status >= 2 && this.cardType === 'generic') || this.cardType !== 'generic')) {
      this.btnType = action === 'enter' ? this.isFlexibleCompensation ? 'ricEnergyDark' : 'primaryDark' : this.isFlexibleCompensation ? 'ricEnergyLight' : 'secondaryDark';
      this.btnCard.changeType(this.btnType);
      const titleHoverBase = this.isFlexibleCompensation ? 'text-[#0f5e42]' : 'text-orange-100';
      if (this.hasTitle) {
        this.titleClass = action === 'enter' ? `text-left line-clamp-2 ${titleHoverBase} ${this.titleStyles[this.size]}` : `text-left line-clamp-2 text-eggplant-80 hover:text-orange-100 ${this.titleStyles[this.size]}`;
      } else {
        this.titleClass = 'hidden';
      }
    }
  }

  viewProject() {
    if (((this.project.status >= 2 && this.cardType === 'generic') || this.cardType !== 'generic')) {
      this.viewProjectEvent.emit(this.project);
      this.sidemenuService.toggleMenu();
      setTimeout(() => this.getItemStyles(), 50);
    }
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: NewDetailComponent,
      backdropDismiss: false,
      animated: true,
      cssClass: 'my-modal',
      componentProps: {
        project: this.project
      }
    });
    modal.present();
    modal.onDidDismiss().then(data => {
      this.opened = false;
      this.sidemenuService.toggleMenu();
    });
  }

  removeModal() {
    this.modalOpened = false;
    this.viewProject();
  }

  getLocaleDescription(locale) {
    if (locale) {
      for (let i = 0; i < locale.length; i++) {
        if (locale[i].language === this.translate.currentLang) {
          return locale[i].description !== undefined ? locale[i].description : locale[i].content;
        }
      }
    }
  }

  getItemStyles() {
    if (this.opened) {
      const openedStyles = 'fun-card bg-white block transition-all duration-500 opened-card fixed w-full h-full left-0 z-100 overflow-auto';
      if ((this.project.status >= 2 && this.cardType === 'generic') || this.cardType !== 'generic') {
        this.itemClass = `${this.borderStyles} ${openedStyles} hover:cursor-pointer ${this.itemStyles[this.orientation]} ${this.itemStyles[this.size + '-' + this.orientation]}`;
      } else {
        this.itemClass = `${this.borderStyles} ${openedStyles} ${this.itemStyles[this.orientation]} ${this.itemStyles[this.size + '-' + this.orientation]}`;
      }
    } else {
      this.itemClass = `${this.borderStyles} ${this.itemStl} ${this.itemStyles[this.orientation]} ${this.itemStyles[this.size + '-' + this.orientation]}`;
    }
    this.chdRef.detectChanges();
  }

  controlOffersnumber() {
    let i: number;
    if (this.project.offers && this.project.offers.length) {
      let times = 0;
      this.offerNumber = '('+ this.project.offers.length + ')';
      for (i = 0; i < this.project.offers.length; i++) {
        if (times <= 2) {
          this.offers.push(this.project.offers[i]);
        }
        times ++;
      }
    } else {
      this.offerNumber = '(0)';
    }
    if (this.offers.length === 0) {
      this.mkpBtntitle = this.translate.instant('offer.create-offer-button');
    } else {
      this.mkpBtntitle =  `${this.translate.instant('card.see-offers')} ${this.offerNumber}`;
    }
    this.chdRef.detectChanges();
  }

}
