import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import * as momentNs from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { OverviewProjectService } from 'src/app/services/overview-project.service';
import { UtilsService } from 'src/app/services/utils.service';
const moment = momentNs;
@Component({
  selector: 'app-overview-status-project',
  templateUrl: './overview-status-project.component.html',
  styleUrls: ['./overview-status-project.component.scss'],
})
export class OverviewStatusProjectComponent implements OnInit {
  //@ViewChild('inputInvest', { static: false }) inputInvestRef: InputComponent;
  @Output() private eventClickViewBTN = new EventEmitter<any>();
  @Output() private downloadAllDocumentsEvent = new EventEmitter<any>();
  @Output() private editInvestEvent = new EventEmitter<any>();
  @Output() private calculateBeneffitsEvent = new EventEmitter<any>();
  @Input() info: any;
  @Input() isFlexibleCompensation: boolean = false;

  projectStatus: any;
  projectId: any;
  remainDays: any;
  financiedIn: any;
  end: any;
  percent: any;
  percentPx: any;
  profit: number;
  prevent: number;
  percentIncrease: any;
  percentIncreaseReserv: any;
  hasPrevInvest: boolean;
  btnInvest: string;
  btnInvested: string;

  moneyInvested: any;
  moneyExpected: any;
  percentInv: any;
  leftInv: any;

  daysR: any;
  day: any;
  month: any;
  moreThanAvailable: boolean;
  inputValue: any;

  projectProd = false;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorLogin = false;
  public isLocalInvestProject = false;
  public localInvestDate: any;

  enerparcPercent = '63%';
  firstTime = false;
  allowedIncrease = false;
  endPrivateDate: string;
  progresBarPercent: string;
  percentIncreased: any;

  limitDate: string;
  daysFromStart: any;
  objetiveParse: number;
  yourInvest = 0;

  btnMain: string;

  constructor(
    private translate: TranslateService,
    private overviewService: OverviewProjectService,
    private utils: UtilsService,
    private chdRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.getAsideData();
    this.chdRef.detectChanges();
  }

  getAsideData() {
    this.btnMain = this.isFlexibleCompensation ? 'ricEnergyDark' : 'primaryDark';
    registerLocaleData(es);
    if (this.info.prevInvest !== undefined) {
      for (let i = 0; i < this.info.prevInvest.length; i++) {
        this.yourInvest = this.yourInvest + this.info.prevInvest[i].invested;
      }
    }
    if (
      this.info.max_allowed_amount_to_invest_for_user !== null
      && this.info.max_allowed_amount_to_invest_for_user !== undefined
      ) {
      this.allowedIncrease = true;
    }
    if (this.info.status === 14) {
      this.endPrivateDate = this.getEndPrivateDay();
    }
    if (this.info.status === 5 || this.info.status === 6 || this.info.status === 7) {
      this.financiedIn = this.getFinanciedIn();
    }
    this.moreThanAvailable = true;
    this.getBtnTitle();
    this.projectStatus = this.info.status;
    this.projectId = this.info.id;
    let now = new Date(this.convertToIos(moment().format('YYYY-MM-DD HH:mm:ss'))).getTime();
    let start = new Date(
      this.convertToIos(moment(this.info.start_date).format('YYYY-MM-DD') + ' 00:00:00')
    ).getTime();
    let endDateN = new Date(
      this.convertToIos(moment(this.info.end_date).format('YYYY-MM-DD') + ' 00:00:00')
    ).getTime();
    if (isNaN(endDateN)) {
      endDateN = new Date(
        this.convertToIos(moment(this.info.end_date.replace('/', '-').replace('/', '-')).format('YYYY-MM-DD') + ' 00:00:00')
      ).getTime();
      now = new Date(moment().format('YYYY-MM-DD HH:mm:ss')).getTime();
      start = new Date(
        this.convertToIos(moment(this.info.start_date.replace('/', '-').replace('/', '-')).format('YYYY-MM-DD') + ' 00:00:00')
      ).getTime();
    }
    this.daysR = Math.ceil((endDateN - now) / (1000 * 60 * 60 * 24));
    if (this.daysR > 1) {
      this.remainDays = this.translate.instant('OVERVIEW.NUMBER_DAYS', {days: this.daysR});
    } else if (this.daysR === 1) {
      this.remainDays = this.translate.instant('OVERVIEW.TOMORROW');
    } else {
      this.remainDays = this.translate.instant('OVERVIEW.TODAY');
    }
    this.end = moment(this.info.end_date.replace('/', '-').replace('/', '-')).format('DD/MM/YYYY');
    if (this.info.status === 15 || this.info.status === 12 || this.info.status === 4) {
      this.getFinancingDates(this.end, now, start);
    }
    this.percent = (this.info.contributed / this.info.total) * 100;
    if (this.info.status === 14) {
      const initial = ((this.info.total - this.info.capital_increase_amount) / this.info.total) * 100;
      this.progresBarPercent = `${initial}%`;
      this.percentIncreased = (this.info.contributed * 100) / (this.info.total - this.info.capital_increase_amount);
    } else {
      this.progresBarPercent = `${this.percent}%`;
    }
    this.percentPx = `${this.percent}%`;
    this.objetiveParse = Number(this.info.total) / 1000000;
    this.checkLocalInvest();
    if ((this.info.status === 4 || this.info.status === 12) && this.info.reserved !== null && this.info.reserved !== undefined) {
      if (this.info.reserved > 0) {
        const percent = ((this.info.reserved + this.info.contributed) / this.info.total) * 100;
        this.percentIncreaseReserv = `${percent}%`;
        this.percent = percent;
      } else {
        this.percentIncreaseReserv = '0%';
      }
    } else {
      this.percentIncreaseReserv = '0%';
    }
    this.chdRef.detectChanges();
  }

  getEndPrivateDay() {
    const check = moment(this.info.capital_increase_private_end_date, 'YYYY/MM/DD');
    const day = Number(check.format('DD'));
    const month = this.utils.parseMonth(Number(check.format('MM')));
    const year = Number(check.format('YYYY'));
    return this.translate.currentLang === 'en' ? `${month} ${day}th, ${year}` : `${day} de ${month} de ${year}`;
  }

  btnClick(evt) {
    if (evt === 'marketplace') {
        if (this.info.hasMarketplace) {
          if (this.info.prevInvest !== undefined) {
            this.eventClickViewBTN.emit('offer');
          } else {
            this.eventClickViewBTN.emit(evt);
          }
        } else {
          this.eventClickViewBTN.emit('updates');
        }
    } else if (evt === 'info') {
      this.overviewService.openCapitalIncreaseMoreInfo(this.info.available);
    } else {
      this.eventClickViewBTN.emit(evt);
    }
  }

  getBtnTitle() {
    if (this.info.investmentsPendingSign && this.info.investmentsPendingSign.length > 0) {
      this.btnInvest = 'Formalizar inversión';
    } else if (this.info.prevInvest && this.info.prevInvest !== undefined && (this.info.status === 4 || this.info.status === 12 || this.info.status === 14 || this.info.status === 15)) {
      this.hasPrevInvest = true;
      this.translate.get('overview.status.invert-again').subscribe((title) => {
        this.btnInvest = title;
      });
      this.getPrevInvest(this.info.prevInvest);
    } else {
      this.hasPrevInvest = false;
      this.translate.get('overview.status.invest').subscribe((title) => {
        this.btnInvest = title;
      });
      this.leftInv = ((this.info.contributed / this.info.total) * 100) + '%';
    }
    if (this.info.status > 4 && this.info.status < 12) {
      if (this.info.hasMarketplace && this.info.hasMarketplace !== undefined) {
        if (this.info.prevInvest !== undefined) {
          this.translate.get('overview.status.publish').subscribe((title) => {
            this.btnInvested = title;
          });
        } else {
          this.translate.get('overview.status.see-offers').subscribe((title) => {
            this.btnInvested = title;
          });
        }
      } else {
        this.translate.get('overview.status.see-news').subscribe((title) => {
          this.btnInvested = title;
        });
      }
    } else {
      this.translate.get('overview.status.see-news').subscribe((title) => {
        this.btnInvested = title;
      });
    }
  }

  getPrevInvest(prev) {
    const invested = [];
    const moneyExpected = [];
    prev.forEach(element => {
      invested.push(element.invested);
      moneyExpected.push(element.totalRevenue);
    });
    this.moneyInvested = invested.reduce((a, b) => a + b, 0);
    this.moneyExpected = moneyExpected.reduce((a, b) => a + b, 0);
    this.percentInv = (this.moneyInvested / this.info.total) * 100 + '%';
    this.leftInv = (((this.info.contributed / this.info.total) * 100)) + '%';
  }

  getFinancingDates(end, now, start) {
    const day = Number(end.substring(0,2));
    const month = this.utils.parseMonthShort(Number(end.substring(3,5)));
    this.limitDate = `${day} ${month}`;
    this.daysFromStart = Math.floor(Math.abs((now - start) / (1000 * 60 * 60 * 24)));
  }

  getFinanciedIn() {
    const startDate = this.info.start_date;
    const endDate = this.info.end_date;
    let endDateN;
    let startDateN;
    let hour;
    endDateN = new Date(this.convertToIos(moment(endDate).format('YYYY-MM-DD  HH:mm:ss'))).getTime();
    startDateN = new Date(this.convertToIos(moment(startDate).format('YYYY-MM-DD  HH:mm:ss'))).getTime();
    if (endDateN === isNaN || endDateN === undefined || !endDateN) {
      endDateN = new Date(
        this.convertToIos(moment(endDate.replace('/', '-').replace('/', '-')).format('YYYY-MM-DD  HH:mm:ss'))
      ).getTime();
      startDateN = new Date(
        this.convertToIos(moment(startDate.replace('/', '-').replace('/', '-')).format('YYYY-MM-DD  HH:mm:ss'))
      ).getTime();
    }

    const h = (new Date(endDateN).getTime() - new Date(startDateN).getTime()) / (1000 * 60);
    if (h <= 1) {
      this.translate.get('overview.status.less-than-hour').subscribe((title) => {
        hour = title;
       });
    } else {
      if (h > 1 && h < 2) {
        this.translate.get('overview.status.one-hour').subscribe((title) => {
          hour = title;
         });
      } else {
        hour = this.translate.instant('OVERVIEW.FINANCED_IN', { hour: Math.floor(h) });
      }
    }
    const timeLeft = (endDateN - startDateN) / (1000 * 60 * 60 * 24);
    if (timeLeft < 1) {
      return hour;
    } else if (timeLeft > 1 && timeLeft < 2) {
      this.translate.get('overview.status.financied-day').subscribe((title) => {
        return title;
       });
    }
    return this.translate.instant('OVERVIEW.FINANCED_IN_DAYS', { days: Math.round(timeLeft) });
  }

  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }

  checkLocalInvest() {
    if (
      (this.info.status === 4 || this.info.status === 12)
      && this.info.postalCodeToInvest
      && this.info.postalCodeDate !== null
      && this.info.postalCodeDate !== undefined
      && !this.info.postalCodeCanInvest
      ) {
      this.isLocalInvestProject = true;
      let dateParse = new Date(this.convertToIos(moment(this.info.postalCodeDate).tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        dateParse = new Date(this.convertToIos(moment(this.info.postalCodeDate.replace('/', '-').replace('/', '-'))
        .tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));
      }
      const day = moment(dateParse).date();
      const month = this.utils.parseMonth(moment(dateParse).month() + 1);
      const year =  moment(dateParse).year();
      this.localInvestDate = this.translate.instant('OVERVIEW.PROJECT_RESERVATED', {day: day, month: month, year: year});
    }
  }

  editInvest() {
    this.editInvestEvent.emit();
  }

  reserveInvestment() {
    this.eventClickViewBTN.emit('invest');
  }

  downloadAllDocuments() {
    this.downloadAllDocumentsEvent.emit();
  }

  calculateB() {
    this.calculateBeneffitsEvent.emit();
  }

}
