import { EventEmitter, Injectable, Output } from '@angular/core';
import { HelpService } from 'src/app/services/help.service';
import { TranslateService } from '@ngx-translate/core';
import { CancelInvestMovementComponent } from 'src/app/shared/components/cancel-invest-movement/cancel-invest-movement.component';
import { CapitalIncreaseMoreInfoComponent } from 'src/app/shared/components/capital-increase-more-info/capital-increase-more-info.component';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root',
})
export class OverviewProjectService {

  @Output() public investrejectedEvent = new EventEmitter<any>();
  @Output() public openInvestEvent = new EventEmitter<any>();

  public currentPage: string;

  constructor(
    private communicationService: CommunicationService,
    public helpService: HelpService,
    private alertService: AlertService,
    private translate: TranslateService,
    private toastService: ToastService,
    private modalService: ModalService
    ) {}

  getCurrentPage() {
    this.helpService.openHelp('first', 0, 'MARKETPLACE_DETAILPROJ');
    /*switch (this.currentPage) {
      case 'marketplace':
        this.helpService.openHelp('first', 0, 'MARKETPLACE_DETAILPROJ');
        break;
    }*/
  }

  public getUpdateProject(id: string) {
    return this.communicationService.get('apiservices/project/v1/projects/' + id + '/updates', null);
  }
  public getProjectDetail(id: string) {
    return this.communicationService.get('apiservices/project/v1/projects/' + id, null);
  }
  public getProjectDetailBySlug(slug: string) {
    return this.communicationService.get('apiservices/project/v1/projects/slug/' + slug, null);
  }
  public getProjectFiles(id: string) {
    return this.communicationService.get('apiservices/project/v1/projects/' + id + '/files', null);
  }
  public getStatusProject(id: string) {
    return this.communicationService.get('apiservices/investment/v1/investments/projects/' + id + '/info/sum', null);
  }
  public getMarketplaceInfo() {
    return this.communicationService.get('apiservices/marketplace/v1/marketplace/openProjects', null);
  }
  public getBalance() {
    return this.communicationService.get('apiservices/profile/v1/profiles/wallet/balance', null);
  }
  public getQuestionsLoan() {
    return this.communicationService.get('apiservices/utils/v1/utils/get/survey/loan', null);
  }
  public getQuestionsShares() {
    return this.communicationService.get('apiservices/utils/v1/utils/get/survey/shares', null);
  }
  public validateBeforeInvest(model: any, id: any) {
    return this.communicationService.post('apiservices/investment/v1/investments/projects/' + id + '/validateBeforeInvest', model);
  }
  public convenienceTest(model: any) {
    return this.communicationService.post('apiservices/profile/v1/profiles/conveniencetest', model);
  }
  public invest(model: any, id: any) {
    return this.communicationService.post('apiservices/investment/v1/investments/projects/' + id + '/invest', model);
  }
  public finishInvest(transactionId: any) {
    return this.communicationService.put('apiservices/investment/v1/investments/finishInvest/' + transactionId, null);
  }
  public getInvestAvailable(id: any) {
    return this.communicationService.get(`apiservices/project/v1/projects/${id}/available`, null);
  }
  public getReservationAvailable(id: any) {
    return this.communicationService.get(`apiservices/project/v1/projects/${id}/availableforinvestmentreservation`, null);
  }
  public getWalletStatus() {
    return this.communicationService.get('apiservices/profile/v1/profiles/wallets/status', null);
  }
  public getQueue(id) {
    return this.communicationService.get('apiservices/investment/v1/investments/projects/' + id + '/info/queue', null);
  }
  public getProjectsInvest(id) {
    return this.communicationService.get('apiservices/investment/v1/investments/projects/' + id + '/info/sum', null);
  }
  public getHasInvest(id) {
    return this.communicationService.get('apiservices/investment/v1/investments/projects/' + id + '/statistics', null);
  }
  public getCancelInvest(id) {
    return this.communicationService.get('apiservices/investment/v1/investments/cancelInvest/' + id, null);
  }
  public getUpdateFile(id, uuid) {
    return this.communicationService.get('apiservices/project/v1/projects/' + id + '/updates/files/' + uuid, null, 'blob'); // /v1/projects/{idProject}/updates/files/{uuid} blob'
  }
  public getStatusSign(id) {
    return this.communicationService.get('apiservices/signaturit/v1/signaturit/status/completed/' + id, null);
  }
  public getPendingPromos() {
    return this.communicationService.get('apiservices/investment/v1/investments/pendingPromos', null);
  }
  public rejectPreviousInvest(id) {
    return this.communicationService.put('apiservices/investment/v1/investments/regret/' + id, null);
  }
  public getPreviousInvestList(id) {
    return this.communicationService.get('apiservices/investment/v1/investments/project/' + id, null);
  }
  public getReservationList() {
    return this.communicationService.get('apiservices/investmentintention/v1/investmentintentions', null);
  }
  public reserveInvestment(model) {
    return this.communicationService.post('apiservices/investmentintention/v1/investmentintentions', model);
  }
  public cancelReservation(id) {
    return this.communicationService.put(`apiservices/investmentintention/v1/investmentintentions/cancel/${id}`, null);
  }
  public getTotalProjectreservedByUser(id) {
    return this.communicationService.get(`apiservices/investmentintention/v1/investmentintentions/projects/${id}`, null);
  }
  public getTotalReservedAmount(id) {
    return this.communicationService.get(`apiservices/investmentintention/v1/investmentintentions/projects/${id}/totalreservedamount`, null);
  }
  public getCalendarInfo(model) {
    return this.communicationService.post('apiservices/project/v1/projects/paymentcalendar', model);
  }
  public createSign(model) {
    return this.communicationService.post(`apiservices/investment/v1/investments/projects/${model.projectId}/sign/${model.investId}`, null);
  }
  public getLleidanetSignStatus(id) {
    return this.communicationService.get(`apiservices/lleidanet/v1/lleidanet/sign-status/${id}`, null);
  }

  cancelInvestment(movement?: any) {
    const modalParams = {
      investmentList: movement
    };
    this.alertService.showModal(CancelInvestMovementComponent, modalParams, '', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: CancelInvestMovementComponent) => {
        componentRef.investrejectedEvent.subscribe((resp) => {
          this.rejectPreviousInvest(resp[0].id).subscribe(() => {
            this.investrejectedEvent.emit();
            this.toastService.showToast(this.translate.instant('CANCEL_INVEST.DONE'), 'success', true);
            this.alertService.removeModal();
          });
        });
        componentRef.goBackEvent.subscribe((resp) => {
          this.alertService.removeModal();
        });
      });
      modal.onClose().subscribe();
    });
  }

  openCapitalIncreaseMoreInfo(availableInvest) {
    const modalParams = {
      available: availableInvest
    };
    this.modalService.showModal(CapitalIncreaseMoreInfoComponent, modalParams, this.translate.instant('OVERVIEW.CAPITAL_INCREASE.TITLE')).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: CapitalIncreaseMoreInfoComponent) => {
        componentRef.goBackEvent.subscribe(() => {
          this.modalService.removeModal();
        });
        componentRef.investEvent.subscribe(() => {
          this.modalService.removeModal();
          this.openInvestEvent.emit();
        });
      });
      modal.onClose().subscribe();
    });
  }
}
