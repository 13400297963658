import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { OverviewProjectService } from 'src/app/services/overview-project.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invest-ifr',
  templateUrl: './invest-ifr.component.html',
  styleUrls: ['./invest-ifr.component.scss'],
})
export class InvestIfrComponent implements OnInit, OnDestroy {
  @Input() transaction: any;
  @Input() ifr: any;
  @Input() transactionId: any;
  @Input() projectData: any;
  @Output() public signEvent = new EventEmitter<any>();
  @Output() public signErrorEvent = new EventEmitter<any>();
  @Output() public eventCloseModalCard = new EventEmitter<any>();
  @Output() public eventNewInvest = new EventEmitter<any>();
  @Output() public eventStopTime = new EventEmitter<any>();
  @Output() public startSignEvent = new EventEmitter<any>();
  @Output() public signaturitIssueEvent = new EventEmitter<any>();
  @Output() public purchaseEvent = new EventEmitter<any>();
  @Output() public editInvestEvent = new EventEmitter<any>();
  @Output() public goToPreviewDocumentsEvent = new EventEmitter<any>();
  @Output() public lleidanetSignOkEvent = new EventEmitter<any>();
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  urlSafe: SafeResourceUrl;
  isCharged = false;

  projectName;
  status;
  errorPas;
  projectId;
  investment;
  readDocuments = false;
  signDocuments = false;
  paymentType: string;

  isLleidanet: boolean;
  lleidanetSignStatus: string;
  syncInterval: any;
  lleidanetOpen: boolean;

  btnList = [
    {
      type: 'primaryDark',
      index: 1,
      title: this.translate.instant('SIGN.BTN'),
    },
    {
      type: 'secondaryDark',
      index: 2,
      title: this.translate.instant('AUTOINVEST.FOOTER.PREV'),
    }
  ];

  constructor(
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private amplitudeService: AmplitudeService,
    private overviewProjectService: OverviewProjectService,
    private translate: TranslateService
    ) {
    window.addEventListener('message', this.receiveMessage, false);
  }

  ngOnInit() {
    this.isLleidanet = false;
    this.lleidanetOpen = false;
    registerLocaleData(es);
    if (this.transaction.signaturit) {
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.transaction.signaturit);
      switch (this.ifr) {
        case 1:
          this.operateSignaturit();
          break;
        case 2:
          this.operateLemonway();
          break;
      }
      this.amplitudeService.trackEvent('invest_sign_documents', {});
    } else {
      this.opearteLleidanet();
    }

    this.cdRef.detectChanges();
  }
  ngAfterViewInit(): void {}

  receiveMessage(event) {
    this.cdRef.detectChanges();
  }
  operateSignaturit() {
    this.startSignEvent.emit();
    window.addEventListener('message', e => {
      // ready, signed
      this.isCharged = true;
      this.cdRef.detectChanges();
      if (e.data.event === 'completed') {
        this.lleidanetSignOkEvent.emit();
        this.amplitudeService.trackEvent('invest_sign_ok', {});
      } else {
        if (e.data.event === 'declined' || e.data.event === 'declined') {
          this.signErrorEvent.emit();
          this.amplitudeService.trackEvent('invest_sign_ko', { status: e.data.event });
        }
      }
      if (e.data.event === 'ready') {
        this.readDocuments = true;
        if (this.projectData && this.projectData && undefined && this.projectData.investByCard) {
          this.paymentType = 'Card';
        } else {
          this.paymentType = 'Wallet';
        }
      }
      if (e.data.message === 'signaturit issue') {
        this.signaturitIssueEvent.emit();
      }
    });
    this.cdRef.detectChanges();
  }
  operateLemonway() {
    window.addEventListener('message', e => {
      switch (e.data.message) {
        case 'close timer':
          this.eventStopTime.emit();
          break;
        case 'go home':
          this.eventCloseModalCard.emit();
          break;
        case 'new invest':
          this.eventNewInvest.emit();
          break;
        case 'purchase':
          this.purchaseEvent.emit(e.data.data);
          break;
      }
    });
    this.cdRef.detectChanges();
  }
  charged(event) {
    if (event.type === 'load') {
      setTimeout(() => {
        this.isCharged = true;
      }, 5000);
    }
    if (this.ifr === 2) {
      window.addEventListener('message', e => {
      });
      this.route.queryParams.subscribe(queryParams => {
        this.route.params.subscribe(routeParams => {
          setTimeout(() => {
            this.isCharged = true;
          }, 5000);
        });
      });
    }
    this.cdRef.detectChanges();
  }
  prev() {
    if (this.projectData.signDocuments !== undefined && this.projectData.signDocuments !== null && this.ifr === 1) {
      this.goToPreviewDocumentsEvent.emit();
    } else {
      this.editInvestEvent.emit();
    }
  }

  async opearteLleidanet() {
    this.isLleidanet = true;
    this.lleidanetOpen = true;

    let newWindow: Window | null;
    let times: number = 0;
    const lleidanetUrl = this.transaction.lleidanet;

    if (Capacitor.isNativePlatform()) {
      console.log('is native platform')
      this.syncInterval = setInterval(() => {
        this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
          if (resp.signature_status === 'signed') {
            clearInterval(this.syncInterval);
            this.lleidanetSignOkEvent.emit();
            this.lleidanetSignStatus = 'OK';
            this.lleidanetOpen = false;
            Browser.close();
          }
          this.cdRef.detectChanges();
        });
        //this.cdRef.detectChanges();
      }, 2000);
      // Abrir la URL en el navegador del sistema para permitir redirecciones
      await Browser.open({ url: lleidanetUrl, presentationStyle: 'popover' });

      Browser.addListener('browserPageLoaded', () => {
        console.log('browserPageLoaded')
          this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
            if (resp.signature_status === 'signed') {
              this.lleidanetSignOkEvent.emit();
              this.lleidanetSignStatus = 'OK';
              this.lleidanetOpen = false;
              Browser.close();
              console.log('close browserPageLoaded')
            }
            this.cdRef.detectChanges();
          });
      });

      Browser.addListener('browserFinished', () => {
        console.log('browserFinished')
          this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
            if (resp.signature_status === 'signed') {
              this.lleidanetSignOkEvent.emit();
              this.lleidanetSignStatus = 'OK';
              this.lleidanetOpen = false;
            } else {
              this.lleidanetSignStatus = 'KO';
              this.lleidanetOpen = false;
            }
            Browser.close();
            console.log('close browserFinished')
            this.cdRef.detectChanges();
          });
          this.cdRef.detectChanges();
      });

    } else {
      console.log(lleidanetUrl)
      // Para la web, abre en una nueva pestaña y guarda la referencia
      newWindow = window.open(lleidanetUrl, '_blank');
      // Configurar un intervalo para escuchar el cierre de la ventana
      this.syncInterval = setInterval(() => {
        if (newWindow) {
          // Verifica si la ventana sigue abierta
          if (newWindow.closed) {
            // Aquí por si acaso debería de hacer la petición de nuevo con una variable local, controlada de la siguiente manera.
            // Si he firmado y demas la pongo a false y si no, la dejo a true y en cuanto cierre la pestaña compruebe
            clearInterval(this.syncInterval);
            this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
              if (resp.signature_status === 'signed') {
                this.lleidanetSignOkEvent.emit();
                this.lleidanetSignStatus = 'OK';
                this.lleidanetOpen = false;
                newWindow.close();
              } else {
                // XXX Aqui como ha cerrado le debo de mostrar un mensaje en plan, oye mira, no se ha firmado, hasa que no firmes no se materializa tu inversión
                this.lleidanetSignStatus = 'KO';
                this.lleidanetOpen = false;
              }
              this.cdRef.detectChanges();
            });
            this.cdRef.detectChanges();
          } else {
            // Puedes acceder a la URL actual de la nueva ventana
            try {
              //clearInterval(syncInterval);
              const newWindwUrl = newWindow.location.href;
              const parametersString = this.getUrlParameters(newWindwUrl);
              if (!newWindow.closed) {
                newWindow.close(); // Cierra la nueva ventana
                newWindow = null;
                clearInterval(this.syncInterval);
                if (parametersString.status === 'signed') {
                  this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
                    if (resp.signature_status === 'signed') {
                      this.lleidanetSignOkEvent.emit();
                      this.lleidanetSignStatus = 'OK';
                      this.lleidanetOpen = false;

                    }
                    //this.checkLleidanetSign(resp);
                    this.cdRef.detectChanges();
                  });
                  // Puedo mirar y asegurarme de que haya firmado bien
                } else {
                  // Hacer peticion para ver si ha firmado bien o no por si acaso
                  this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
                    if (resp.signature_status === 'signed') {
                      this.lleidanetSignOkEvent.emit();
                      this.lleidanetSignStatus = 'OK';
                      this.lleidanetOpen = false;
                      if (!newWindow.closed) {
                        newWindow.close();
                      }
                    }
                    this.cdRef.detectChanges();
                  });
                }
              }
              this.cdRef.detectChanges();
            } catch (error) {
              // Peticion cada 4 veces para ver el estado de la firla.
              times++;
              if (times % 4 === 0) {
                this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
                  if (resp.signature_status === 'signed') {
                    this.lleidanetSignOkEvent.emit();
                    this.lleidanetSignStatus = 'OK';
                    this.lleidanetOpen = false;
                    if (!newWindow.closed) {
                      newWindow.close();
                    }
                  }
                  //this.checkLleidanetSign(resp);
                  this.cdRef.detectChanges();
                });
              }
              this.cdRef.detectChanges();
            }
          }
        }
        this.cdRef.detectChanges();
      }, 2000);
    }
    this.cdRef.detectChanges();
  }

  getUrlParameters(url: string): Record<string, string> {
    const urlObj = new URL(url); // Crear un objeto URL
    const params = new URLSearchParams(urlObj.search); // Obtener los parámetros de búsqueda
    const paramsObject: Record<string, string> = {}; // Crear un objeto vacío para almacenar los parámetros

    // Llenar el objeto con los parámetros
    params.forEach((value, key) => {
        paramsObject[key] = value; // Asignar el valor al objeto usando la clave
    });

    return paramsObject; // Retornar el objeto de parámetros
  }

  controlOveralBtn(id) {
    if (id === 1) {
      this.lleidanetSignStatus = null;
      this.opearteLleidanet();
    } else {
      this.eventCloseModalCard.emit();
    }
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    clearInterval(this.syncInterval);
    this.cdRef.detectChanges();
  }
}
