<div class="general-container">
  <div *ngIf="readDocuments" id="read-documents" class="span-container">
      <span id="payment-type">{{ paymentType }}</span>
      <!--<span id="item-name">{{ projectData.headerInfo.name }}</span>
      <span id="item-id">{{ projectData.investInfo.id }}</span>
      <span id="item-brand">{{ projectData.spanInfo.company }}</span>
      <span id="item-category">Energía Solar</span>
      <span id="item-location">{{ projectData.spanInfo.location }}, ES</span>
      <span id="item-profit">{{ projectData.headerInfo.profitability | number:'1.0-2':'es' }}</span>
      <span id="item-goal">{{ projectData.headerInfo.objective | number:'1.0-2':'es' }} €</span>
      <span id="item-index">{{ projectData.spanInfo.position }}</span>-->
      <span id="item-price">{{ projectData.moneyInvested }}</span>
      <span id="list-name">En Financiación</span>
  </div>

  <div class="content" *ngIf="!isLleidanet">
      <div class="skl" *ngIf="!isCharged">
          <fun-spinner *ngIf="!isCharged"></fun-spinner>
      </div>

      <iframe
          [src]="urlSafe"
          frameborder="0"
          (load)="charged($event)"
          #iframe
          allowfullscreen
          webkitallowfullscreen
          mozallowfullscreen
      ></iframe>
  </div>

  <div class="button-container" *ngIf="isCharged && !isLleidanet">
      <div class="prev">
          <fun-new-button size="xl" (eventClick)="prev()" icon="arrow-left" [iconPosition]="'left'" [classExtra]="' !w-full '"
          [buttonType]="'ghost'" title="{{'register.btn.back' | translate}}"></fun-new-button>
      </div>
  </div>

  <div *ngIf="isLleidanet">
    <div class="py-72 w-full flex flex-col gap-72 items-center justify-center content-center appear" *ngIf="lleidanetOpen">
        <div class="md:pt-[120px]"><fun-icon [name]="'file-text'" [style]="'w-72 h-[86px] stroke-[4px] stroke-eggplant-70 animate-gradientX'"></fun-icon></div>
        <p class="text-center font-sans text-h600 md:text-h800 text-eggplant-70 animate-gradientX">{{ 'SIGN.PROCCESS_OTHER_WINDOW' | translate }}</p>
        <div class="flex items-center justify-center">
            <fun-new-button [buttonType]="'colorFull'" [size]="'xl'" title="{{ 'bid-status.sign-now' | translate }}"
            iconPosition="right" icon="file-text" (eventClick)="controlOveralBtn(1)" [classExtra]="' w-auto mx-auto '"></fun-new-button>
        </div>
    </div>
    <div *ngIf="lleidanetSignStatus === 'KO'" class="pt-0 md:pt-72">
        <fun-overal-message [text]="'<h2>Proceso cancelado</h2><p>Al parecer has abandonado el proceso de firma, debes completarlo en menos de cuatro días o tu inversión será cancelada</p>'" [image]="1" [btnList]="btnList"
            (btnEvent)="controlOveralBtn($event)"></fun-overal-message>
    </div>
  </div>
</div>