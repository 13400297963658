import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OverviewProjectService } from 'src/app/services/overview-project.service';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'fun-formalize-signature',
  templateUrl: './formalize-signature.component.html',
  styleUrls: ['./formalize-signature.component.scss'],
})
export class FormalizeSignatureComponent implements OnInit, OnDestroy {

  @Input() projectId: any;
  @Input() investId: any;
  @Output() public signedEvent = new EventEmitter<any>();
  @Output() public removeModalEvent = new EventEmitter<any>();

  loading: boolean;
  transaction: any;
  signStatus: string;
  syncInterval: any;
  lleidanetOpen: boolean;

  btnList = [
    {
      type: 'primaryDark',
      index: 1,
      title: this.translate.instant('SIGN.BTN'),
    },
    {
      type: 'secondaryDark',
      index: 2,
      title: this.translate.instant('AUTOINVEST.FOOTER.PREV'),
    }
  ];
  text: string;
  image: number;
  signType: string;
  urlSafe: SafeResourceUrl;

  constructor(
    private overviewProjectService: OverviewProjectService,
    private sanitizer: DomSanitizer,
    private chdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.text = this.translate.instant('SIGN.CANCELED');
    this.image = 1;
    if (this.projectId && this.investId) {
      const model = {
        investId: this.investId,
        projectId: this.projectId
      }
      this.overviewProjectService.createSign(model).subscribe(resp => {
        this.transaction = resp.transaction;
        if (resp.transaction.lleidanet) {
          this.signType = 'lleidanet';
          this.opearteLleidanet();
        } else if (resp.transaction && resp.transaction.signaturit) {
          this.signType = 'signaturit';
          this.operateSignaturit();
        } else {
          this.finishInvest();
        }
        this.chdRef.detectChanges();
      }, error => {
        this.loading = false;
        this.text = this.translate.instant('SIGN.EXPIRED');
        this.image = 4;
        this.btnList = [
          {
            type: 'primaryDark',
            index: 2,
            title: this.translate.instant('AUTOINVEST.FOOTER.NEXT'),
          }
        ];
        this.signStatus = 'completed';
        if (error.error.errors && error.error.errors.length > 0) {
          setTimeout(() => {
            this.utilsService.showErrors(error.error.errors);
          }, 400);
        }
        this.chdRef.detectChanges();
      });
    } else {
      this.loading = false;
      this.text = this.translate.instant('SIGN.EXPIRED');
      this.image = 4;
      this.btnList = [
        {
          type: 'primaryDark',
          index: 2,
          title: this.translate.instant('AUTOINVEST.FOOTER.NEXT'),
        }
      ];
      this.signStatus = 'completed';
      this.chdRef.detectChanges();
    }
  }

  operateSignaturit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.transaction.signaturit);
    this.loading = false;
    window.addEventListener('message', e => {
      if (e.data.event === 'completed') {
        this.signStatus = 'completed';
        this.loading = true;
        this.finishInvest();
        this.chdRef.detectChanges();
        //this.lleidanetSignOkEvent.emit();
        //this.amplitudeService.trackEvent('invest_sign_ok', {});
      } else {
        if (e.data.event === 'declined') {
          this.signStatus = 'KO';
          //this.signErrorEvent.emit();
          //this.amplitudeService.trackEvent('invest_sign_ko', { status: e.data.event });
        }
      }
      if (e.data.event === 'ready') {
        this.loading = false;
        this.chdRef.detectChanges();
        // this.readDocuments = true;
        // if (this.projectData && this.projectData && undefined && this.projectData.investByCard) {
        //   this.paymentType = 'Card';
        // } else {
        // this.paymentType = 'Wallet';
        // }
      }
      // if (e.data.event === 'signed') {}
      if (e.data.message === 'signaturit issue') {
        this.signStatus = 'KO';
        //this.signaturitIssueEvent.emit();
        this.chdRef.detectChanges();
      }
      this.chdRef.detectChanges();
    });
    this.chdRef.detectChanges();
  }

  async opearteLleidanet() {
    this.loading = false;
    this.lleidanetOpen = true;
    let newWindow: Window | null;
    let times: number = 0;
    const lleidanetUrl = this.transaction.lleidanet;

    if (Capacitor.isNativePlatform()) {
      this.syncInterval = setInterval(() => {
        this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
          if (resp.signature_status === 'signed') {
            Browser.close();
            this.loading = true;
            this.lleidanetOpen = false;
            this.finishInvest();
            clearInterval(this.syncInterval);
          }
          this.chdRef.detectChanges();
        });

        //this.chdRef.detectChanges();
      }, 2000);
      // Abrir la URL en el navegador del sistema para permitir redirecciones
      await Browser.open({ url: lleidanetUrl, presentationStyle: 'popover' });

      Browser.addListener('browserPageLoaded', () => {
        console.log('browserPageLoaded')
          this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
            if (resp.signature_status === 'signed') {
              this.loading = true;
              this.lleidanetOpen = false;
              this.finishInvest();
              Browser.close();
              console.log('close browserPageLoaded')
            }
            this.chdRef.detectChanges();
          });
      });

      Browser.addListener('browserFinished', () => {
        console.log('browserFinished')
          this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
            if (resp.signature_status === 'signed') {
              this.loading = true;
              this.lleidanetOpen = false;
              this.finishInvest();
            } else {
              this.signStatus = 'KO';
              this.lleidanetOpen = false;
            }
            Browser.close();
            console.log('close browserFinished')
            this.chdRef.detectChanges();
          });
      });

    } else {
      // Para la web, abre en una nueva pestaña y guarda la referencia
      newWindow = window.open(lleidanetUrl, '_blank');
      let comleted = false;
      // Configurar un intervalo para escuchar el cierre de la ventana
      this.syncInterval = setInterval(() => {
        if (newWindow) {
          // Verifica si la ventana sigue abierta
          if (newWindow.closed) {
            // Aquí por si acaso debería de hacer la petición de nuevo con una variable local, controlada de la siguiente manera.
            // Si he firmado y demas la pongo a false y si no, la dejo a true y en cuanto cierre la pestaña compruebe
            clearInterval(this.syncInterval);
            this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
              if (resp.signature_status === 'signed' && !comleted) {
                comleted = true;
                this.loading = true;
                this.lleidanetOpen = false;
                this.finishInvest();
                newWindow.close();
              } else {
                // XXX Aqui como ha cerrado le debo de mostrar un mensaje en plan, oye mira, no se ha firmado, hasa que no firmes no se materializa tu inversión
                this.signStatus = 'KO';
                this.lleidanetOpen = false;
              }
              this.chdRef.detectChanges();
            });
          } else {
            // Puedes acceder a la URL actual de la nueva ventana
            try {
              //clearInterval(syncInterval);
              const newWindwUrl = newWindow.location.href;
              const parametersString = this.getUrlParameters(newWindwUrl);
              if (!newWindow.closed) {
                newWindow.close(); // Cierra la nueva ventana
                newWindow = null;
                clearInterval(this.syncInterval);
                if (parametersString.status === 'signed') {
                  this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
                    if (resp.signature_status === 'signed' && !comleted) {
                      comleted = true;
                      this.loading = true;
                      this.lleidanetOpen = false;
                      this.finishInvest();
                    }
                    this.chdRef.detectChanges();
                  });
                  // Puedo mirar y asegurarme de que haya firmado bien
                } else {
                  // Hacer peticion para ver si ha firmado bien o no por si acaso
                  this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
                    if (resp.signature_status === 'signed' && !comleted) {
                      comleted = true;
                      this.loading = true;
                      this.lleidanetOpen = false;
                      if (!newWindow.closed) {
                        newWindow.close();
                      }
                      this.finishInvest();
                    }
                    this.chdRef.detectChanges();
                  });
                }
              }
            } catch (error) {
              // Peticion cada 4 veces para ver el estado de la firla.
              times++;
              if (times % 4 === 0) {
                this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(resp => {
                  if (resp.signature_status === 'signed' && !comleted) {
                    comleted = true;
                    this.loading = true;
                    this.lleidanetOpen = false;
                    if (!newWindow.closed) {
                      newWindow.close();
                    }
                    this.finishInvest();
                  }
                  this.chdRef.detectChanges();
                });
              }
            }
          }
        }
      }, 2000);
    }
    this.chdRef.detectChanges();
  }

  getUrlParameters(url: string): Record<string, string> {
    const urlObj = new URL(url); // Crear un objeto URL
    const params = new URLSearchParams(urlObj.search); // Obtener los parámetros de búsqueda
    const paramsObject: Record<string, string> = {}; // Crear un objeto vacío para almacenar los parámetros

    // Llenar el objeto con los parámetros
    params.forEach((value, key) => {
        paramsObject[key] = value; // Asignar el valor al objeto usando la clave
    });

    return paramsObject; // Retornar el objeto de parámetros
  }

  controlOveralBtn(id) {
    if (id === 1) {
      this.signStatus = null;
      if (this.transaction.lleidanet) {
        this.opearteLleidanet();
      } else {
        this.operateSignaturit();
      }
    } else {
      this.removeModalEvent.emit();
    }
    this.chdRef.detectChanges();
  }

  finishInvest() {
    this.overviewProjectService.finishInvest(this.investId).subscribe(resp => {
      this.text = this.translate.instant('SIGN.OK');
      this.image = 6;
      this.btnList = [
        {
          type: 'primaryDark',
          index: 2,
          title: this.translate.instant('AUTOINVEST.FOOTER.NEXT'),
        }
      ];
      this.signStatus = 'completed';
      this.loading = false;
      this.signedEvent.emit();
      this.chdRef.detectChanges();
    }, error => {
      if (this.transaction.lleidanetID) {
        this.overviewProjectService.getLleidanetSignStatus(this.transaction.lleidanetID).subscribe(response => {
          if (response.signature_status !== 'signed') {
            this.text = `<p>${this.translate.instant('ERRORS.500')}</p>`;
            this.image = 1;
            this.loading = false;
            if (error.error && error.error.errors && error.error.errors.length > 0) {
              this.utilsService.showErrors(error.error.errors);
            }
            this.chdRef.detectChanges();
          }
          this.chdRef.detectChanges();
        });
      }
      this.chdRef.detectChanges();
    });
    this.chdRef.detectChanges();
  }

  ngOnDestroy() {
    clearInterval(this.syncInterval);
    this.chdRef.detectChanges();
  }
}
