import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invest-finish-sign',
  templateUrl: './invest-finish-sign.component.html',
  styleUrls: ['./invest-finish-sign.component.scss'],
})
export class InvestFinishSignComponent implements OnInit {

  @Input() public movement;
  @Output() public signedEvent = new EventEmitter<any>();
  @Output() public removeModalEvent = new EventEmitter<any>();

  step: number;
  text: string = this.translate.instant('SIGN.PENDING');
  image: number = 12;
  btnList = [
    {
      type: 'primaryDark',
      index: 1,
      title: this.translate.instant('SIGN.BTN'),
    }
  ];

  constructor(private cdRef: ChangeDetectorRef, private translate: TranslateService) { }

  ngOnInit() {
    this.step = 0;
    this.cdRef.detectChanges();
  }

  continue() {
    this.step = 1;
    this.cdRef.detectChanges();
  }

  signed() {
    this.signedEvent.emit();
    this.cdRef.detectChanges();
  }

  removeModal() {
    this.removeModalEvent.emit();
    this.cdRef.detectChanges();
  }

}
