import { Injectable } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';

@Injectable({
  providedIn: 'root',
})
export class InvestCardService {

  constructor(private communicationService: CommunicationService) {}

  public getInfoMovement(id: any) {
    return this.communicationService.get('apiservices/investment/v1/investments/infoMovement/' + id, null);
  }

  public registerCard(params: any) {
    return this.communicationService.put('apiservices/investment/v1/investments/cardRegistration/', params);
  }

  public finishInvestByCard(params: any) {
    return this.communicationService.put('apiservices/investment/v1/investments/finishInvestByCard/', params);
  }

  public checkMovementStatus(id) {
    return this.communicationService.get('apiservices/investment/v1/investments/movementStatus/' + id, null);
  }

  public changeInvestToWaitingSign(id) {
    return this.communicationService.post('apiservices/investment/v1/investments/changeState/' + id, null);
  }

}
