import { PublicLayoutComponent } from './components/layout/public/public-layout.component';
import { PrivateLayoutComponent } from './components/layout/private/private-layout.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';

import { ClickOutsideModule } from 'ng4-click-outside';
import { AvatarComponent } from './components/avatar/avatar.component';
import { TranslateModule } from '@ngx-translate/core';
import { GraphicsNavComponent } from './components/graphics-nav/graphics-nav.component';
import { OffersService } from '../services/offers.service';
import { ProductionGraphicComponent } from './components/production-graphic/production-graphic.component';
import { DailyProductionGraphicComponent } from './components/daily-production-graphic/daily-production-graphic.component';
import { IncomeGraphicComponent } from './components/income-graphic/income-graphic.component';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { RegisterHeaderComponent } from './components/register-header/register-header.component';
import { AutomaticInvestComponent } from './components/automatic-invest/automatic-invest.component';
import { AffiliationLocalInvestComponent } from './components/affiliation-local-invest/affiliation-local-invest.component';
import { CancelInvestMovementComponent } from './components/cancel-invest-movement/cancel-invest-movement.component';
import { UpdateListComponent } from './components/update-list/update-list.component';
import { DocumentationListComponent } from './components/documentation-list/documentation-list.component';
import { CalendarPayComponent } from './components/calendar-pay/calendar-pay.component';
import { CalendarPayMonthComponent } from './components/calendar-pay/calendar-pay-month/calendar-pay-month.component';
import { MoneyInComponent } from './components/movements/money-in/money-in.component';
import { MoneyOutComponent } from './components/movements/money-out/money-out.component';
import { MovementListComponent } from './components/movements/movement-list/movement-list.component';
import { MoneyOutChangeAccountComponent } from './components/movements/money-out/money-out-change-account/money-out-change-account.component';
import { TruncatePipe } from './pipes/trunca-date.pipe';
import { OverviewDocumentationSectionComponent } from './components/overview/overview-documentation-section/overview-documentation-section.component';
import { OverviewSectionsComponent } from './components/overview/overview-sections/overview-sections.component';
import { OverviewInvestorsSectionComponent } from './components/overview/overview-investors-section/overview-investors-section.component';
import { OverviewAboutprojectSectionComponent } from './components/overview/overview-aboutproject-section/overview-aboutproject-section.component';
import { OverviewStatusProjectComponent } from './components/overview/overview-status-project/overview-status-project.component';
import { OverviewLocalInvestStepsComponent } from './components/overview/overview-local-invest-steps/overview-local-invest-steps.component';
import { LocalInvestStepsComponent } from './components/overview/overview-local-invest-steps/local-invest-steps/local-invest-steps.component';
import { InvestComponent } from './components/invest/invest.component';
import { InvestBodyComponent } from './components/invest/invest-body/invest-body.component';
import { InvestIfrComponent } from './components/invest/invest-ifr/invest-ifr.component';
import { InvestMangopayComponent } from './components/invest/invest-mangopay/invest-mangopay.component';
import { InvestPopupHeaderComponent } from './components/invest/invest-popup-header/invest-popup-header.component';
import { InvestTestConvenienceComponent } from './components/invest/invest-test-convenience/invest-test-convenience.component';
import { InvestReservedComponent } from './components/invest/invest-reserved/invest-reserved.component';
import { InvestSuccessComponent } from './components/invest/invest-success/invest-success.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterWelcomeComponent } from './components/register/register-welcome/register-welcome.component';
import { RegisterUploadSpecificDocumentComponent } from './components/register/register-upload-specific-document/register-upload-specific-document.component';
import { RegisterSuccessComponent } from './components/register/register-success/register-success.component';
import { RegisterPictureComponent } from './components/register/register-picture/register-picture.component';
import { RegisterPersonLastStepComponent } from './components/register/register-person-last-step/register-person-last-step.component';
import { RegisterPersonLastStepEndComponent } from './components/register/register-person-last-step/register-person-last-step-end/register-person-last-step-end.component';
import { RegisterPersonLastStepDocumentComponent } from './components/register/register-person-last-step/register-person-last-step-document/register-person-last-step-document.component';
import { RegisterPersonLastStepChecksComponent } from './components/register/register-person-last-step/register-person-last-step-checks/register-person-last-step-checks.component';
import { RegisterPersonLastStepAdvantagesComponent } from './components/register/register-person-last-step/register-person-last-step-advantages/register-person-last-step-advantages.component';
import { RegisterPersonComponent } from './components/register/register-person/register-person.component';
import { RegisterPersonPhoneComponent } from './components/register/register-person/register-person-phone/register-person-phone.component';
import { RegisterPersonNifInfoComponent } from './components/register/register-person/register-person-nif-info/register-person-nif-info.component';
import { RegisterPersonNatinalityComponent } from './components/register/register-person/register-person-natinality/register-person-natinality.component';
import { RegisterPersonNameComponent } from './components/register/register-person/register-person-name/register-person-name.component';
import { RegisterPersonEmailComponent } from './components/register/register-person/register-person-email/register-person-email.component';
import { RegisterPersonEditEmailComponent } from './components/register/register-person/register-person-edit-email/register-person-edit-email.component';
import { RegisterPersonDocumentIdComponent } from './components/register/register-person/register-person-document-id/register-person-document-id.component';
import { RegisterPersonCodeComponent } from './components/register/register-person/register-person-code/register-person-code.component';
import { RegisterPersonCivilStatusComponent } from './components/register/register-person/register-person-civil-status/register-person-civil-status.component';
import { RegisterPersonAddressComponent } from './components/register/register-person/register-person-address/register-person-address.component';
import { RegisterIfameComponent } from './components/register/register-ifame/register-ifame.component';
import { RegisterFullIframeComponent } from './components/register/register-ifame/register-full-iframe/register-full-iframe.component';
import { RegisterFileComponent } from './components/register/register-file/register-file.component';
import { RegisterCompanyComponent } from './components/register/register-company/register-company.component';
import { RegisterCompanyRlnationalityComponent } from './components/register/register-company/register-company-rlnationality/register-company-rlnationality.component';
import { RegisterCompanyRldocumentComponent } from './components/register/register-company/register-company-rldocument/register-company-rldocument.component';
import { RegisterCompanyRldataComponent } from './components/register/register-company/register-company-rldata/register-company-rldata.component';
import { RegisterCompanyDataComponent } from './components/register/register-company/register-company-data/register-company-data.component';
import { RegisterCompanyContactPersonComponent } from './components/register/register-company/register-company-contact-person/register-company-contact-person.component';
import { RegisterCompanyAddressComponent } from './components/register/register-company/register-company-address/register-company-address.component';
import { OverviewContractSummaryComponent } from './components/overview/overview-contract-summary/overview-contract-summary.component';
import { BidComponent } from './components/marketplace/bid/bid.component';
import { OfferComponent } from './components/marketplace/offer/offer.component';
import { OfferCreateComponent } from './components/marketplace/offer-create/offer-create.component';
import { OfferStatusComponent } from './components/marketplace/offer-status/offer-status.component';
import { BidCreateComponent } from './components/marketplace/bid-create/bid-create.component';
import { BidStatusComponent } from './components/marketplace/bid-status/bid-status.component';
import { PendingProceduresComponent } from './components/pending-procedures/pending-procedures.component';
import { CapitalIncreaseMoreInfoComponent } from './components/capital-increase-more-info/capital-increase-more-info.component';
import { InvestReservationComponent } from './components/invest/invest-reservation/invest-reservation.component';
import { Capacitor } from '@capacitor/core';
import { RegisterMangopayTermsComponent } from './components/register/register-mangopay-terms/register-mangopay-terms.component';
import { InvestDocumentViewfinderComponent } from './components/invest/invest-document-viewfinder/invest-document-viewfinder.component';
import { UpdateListSliderComponent } from './components/update-list/update-list-slider/update-list-slider.component';
import { YoutubeVideoPlayerComponent } from './components/youtube-video-player/youtube-video-player.component';
import { ReserveInvestmentAdviceComponent } from './components/reserve-investment/reserve-investment-advice/reserve-investment-advice.component';
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';
import { RegisterCompanySpecificPartnerDocComponent } from './components/register/register-company/register-company-specific-partner-doc/register-company-specific-partner-doc.component';
import { TabComponent } from './tab/tab.component';
import { OverviewCommissionsComponent } from './components/overview/overview-commissions/overview-commissions.component';
import { InverstorProfileComponent } from './components/inverstor-profile/inverstor-profile.component';
import { ActionSheetComponent } from './components/action-sheet/action-sheet.component';
import { ActivationWalletMessageComponent } from './components/activation-wallet-message/activation-wallet-message.component';
import { AlertComponent } from './components/alert/alert.component';
import { ButtonComponent } from './components/button/button.component';
import { CodeComponent } from './components/code/code.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { DashboardSubmenuComponent } from './components/dashboard-submenu/dashboard-submenu.component';
import { HelpComponent } from './components/help/help/help.component';
import { HelpMarketplaceComponent } from './components/help/help-marketplace/help-marketplace.component';
import { HelpMobileComponent } from './components/help/help-mobile/help-mobile.component';
import { IncomeInfoComponent } from './components/income-info/income-info.component';
import { InfoComponent } from './components/info/info.component';
import { OveralMessageComponent } from './components/overal-message/overal-message.component';
import { PasswordComponent } from './components/password/password/password.component';
import { PasswordChangeComponent } from './components/password/password-change/password-change.component';
import { PasswordRecoveryComponent } from './components/password/password-recovery/password-recovery.component';
import { RenewSessionComponent } from './components/renew-session/renew-session.component';
import { ToastComponent } from './components/toast/toast.component';
import { WarningComponent } from './components/warning/warning.component';
import { ProjectDetailComponent } from './components/marketplace/project-detail/project-detail.component';
import { ProjectDetailRowComponent } from './components/marketplace/project-detail/project-detail-row/project-detail-row.component';
import { EmptyAlertComponent } from './components/empty-alert/empty-alert.component';
import { FileCardComponent } from './components/file-card/file-card.component';
import { FileComponent } from './components/file/file.component';
import { FilterComponent } from './components/filter/filter.component';
import { FilterMobileComponent } from './components/filter/filter-mobile/filter-mobile.component';
import { FilterOriginatorComponent } from './components/filter-originator/filter-originator.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ViewerComponent } from './components/viewer/viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalComponent } from './components/modal/modal.component';
import { TimeLeftPipe } from './pipes/time-left.pipe';
import { NameProfilePipe } from './pipes/name-profile.pipe';
import { IbanPipe } from './pipes/iban.pipe';
import { BICPipe } from './pipes/bic.pipe';
import { PowerWatiosPipe } from './pipes/power-watios.pipe';
import { Co2Pipe } from './pipes/co2.pipe';
import { NotZeroPipe } from './pipes/not-zero.pipe';
import { LocaleNumberPipe } from './pipes/locale-number.pipe';
import { LocalePercentNumberPipe } from './pipes/locale-percent-number.pipe';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputComponent } from './components/input/input.component';
import { SkeletonCardListComponent } from './components/skeleton/skeleton-card-list.component';
import { SkeletonCardComponent } from './components/skeleton/skeleton-card.component';
import { SkeletonContactDataComponent } from './components/skeleton/skeleton-contact-data.component';
import { SkeletonInputComponent } from './components/skeleton/skeleton-input.component';
import { SkeletonListComponent } from './components/skeleton/skeleton-list.component';
import { SkeletonUpdateComponent } from './components/skeleton/skeleton-update.component';
import { SidemenuIconComponent } from './sidemenu/sidemenu-icon/sidemenu-icon.component';
import { NewCardComponent } from './components/new-card/new-card.component';
import { NewButtonComponent } from './components/new-button/new-button.component';
import { IconComponent } from './components/icon/icon.component';
import { NewDetailComponent } from './components/new-detail/new-detail.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { ContentFeaturedComponent } from './components/content-featured/content-featured.component';
import { NewDetailOverviewComponent } from './components/new-detail/new-detail-overview/new-detail-overview.component';
import { OverviiewStatusAvatarsComponent } from './components/overview/overview-status-project/overviiew-status-avatars/overviiew-status-avatars.component';
import { OvervierCalculatorComponent } from './components/overview/overvier-calculator/overvier-calculator.component';
import { NewDocumentComponent } from './components/new-document/new-document.component';
import { SliderComponent } from './components/slider/slider.component';
import { CalendarPayOverviewComponent } from './components/calendar-pay/calendar-pay-overview/calendar-pay-overview.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageSubmenuComponent } from './components/page-submenu/page-submenu.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { WalletCardComponent } from './components/movements/wallet-card/wallet-card.component';
import { MovementDetailComponent } from './components/movements/movement-detail/movement-detail.component';
import { BadgeGroupComponent } from './components/badge-group/badge-group.component';
import { OverviewStatusSkeletonComponent } from './components/overview/overview-status-skeleton/overview-status-skeleton.component';
import { InvestSuccessTaxdownComponent } from './components/invest/invest-success-taxdown/invest-success-taxdown.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { SubscribeProjectNewsComponent } from './components/subscribe-project-news/subscribe-project-news.component';
import { AskForCommunicationsComponent } from './components/ask-for-communications/ask-for-communications.component';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { RegisterFileCarruselComponent } from './components/register/register-file-carrusel/register-file-carrusel.component';
import { IncomeAnnualGraphicComponent } from './components/income-annual-graphic/income-annual-graphic.component';
import { OverviewMeetingComponent } from './components/overview/overview-meeting/overview-meeting.component';
import { OvervewMeetingListComponent } from './components/overview/overview-meeting/overvew-meeting-list/overvew-meeting-list.component';
import { OvervewMeetingDirectComponent } from './components/overview/overview-meeting/overvew-meeting-direct/overvew-meeting-direct.component';
import { OvervewMeetingChatComponent } from './components/overview/overview-meeting/overvew-meeting-chat/overvew-meeting-chat.component';
import { OverviewMeetingDelegationComponent } from './components/overview/overview-meeting/overview-meeting-delegation/overview-meeting-delegation.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { OverviewMeetingModalComponent } from './components/overview/overview-meeting/overview-meeting-modal/overview-meeting-modal.component';
import { OverviewMeetingDocumentsComponent } from './components/overview/overview-meeting/overview-meeting-documents/overview-meeting-documents.component';
import { RegisterTaxdownComponent } from './components/register/register-taxdown/register-taxdown.component';
import { RegisterTaxdownCodeComponent } from './components/register/register-taxdown/register-taxdown-code/register-taxdown-code.component';
import { ReferralListComponent } from './components/referral/referral-list/referral-list.component';
import { ReferralCardComponent } from './components/referral/referral-card/referral-card.component';
import { AppFormatIbanDirective } from './components/input/app-format-iban.directive';
import { FormalizeSignatureComponent } from './components/formalize-signature/formalize-signature.component';
import { InvestFinishSignComponent } from './components/invest/invest-finish-sign/invest-finish-sign.component';
import { NewGraphicComponent } from './components/new-graphic/new-graphic.component';
import { NewSelectComponent } from './components/new-select/new-select.component';

const fundeenApiKey = 'AIzaSyBc9hWf6eyhpxuMNMS5dlSjL0a-AWV79Q0';
const fundeenApiKeyRestricted = 'AIzaSyBKSdJIRTRXM42G5gEhis7VbVUNCjq6vbE';
const gmapsApiKey = (Capacitor.getPlatform() === 'web') ? fundeenApiKeyRestricted : fundeenApiKey;

@NgModule({
  declarations: [
    HeaderComponent,
    SidemenuComponent,
    AvatarComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    GraphicsNavComponent,
    ProductionGraphicComponent,
    DailyProductionGraphicComponent,
    IncomeGraphicComponent,
    IncomeAnnualGraphicComponent,
    GoogleMapsComponent,
    RegisterHeaderComponent,
    AutomaticInvestComponent,
    AffiliationLocalInvestComponent,
    CancelInvestMovementComponent,
    UpdateListComponent,
    DocumentationListComponent,
    CalendarPayComponent,
    CalendarPayMonthComponent,
    MoneyInComponent,
    MoneyOutComponent,
    MovementListComponent,
    MoneyOutChangeAccountComponent,
    TruncatePipe,
    OverviewDocumentationSectionComponent,
    OverviewSectionsComponent,
    OverviewInvestorsSectionComponent,
    OverviewAboutprojectSectionComponent,
    OverviewStatusProjectComponent,
    OverviewLocalInvestStepsComponent,
    OverviewMeetingComponent,
    LocalInvestStepsComponent,
    InvestComponent,
    InvestBodyComponent,
    InvestIfrComponent,
    InvestMangopayComponent,
    InvestPopupHeaderComponent,
    InvestReservedComponent,
    InvestSuccessComponent,
    InvestTestConvenienceComponent,
    RegisterComponent,
    RegisterWelcomeComponent,
    RegisterUploadSpecificDocumentComponent,
    RegisterSuccessComponent,
    RegisterPictureComponent,
    RegisterPersonLastStepComponent,
    RegisterPersonLastStepEndComponent,
    RegisterPersonLastStepDocumentComponent,
    RegisterPersonLastStepChecksComponent,
    RegisterPersonLastStepAdvantagesComponent,
    RegisterPersonComponent,
    RegisterPersonPhoneComponent,
    RegisterPersonNifInfoComponent,
    RegisterPersonNatinalityComponent,
    RegisterPersonNameComponent,
    RegisterPersonEmailComponent,
    RegisterPersonEditEmailComponent,
    RegisterPersonDocumentIdComponent,
    RegisterPersonCodeComponent,
    RegisterPersonCivilStatusComponent,
    RegisterPersonAddressComponent,
    RegisterIfameComponent,
    RegisterFullIframeComponent,
    RegisterFileComponent,
    RegisterCompanyComponent,
    RegisterCompanyRlnationalityComponent,
    RegisterCompanyRldocumentComponent,
    RegisterCompanyRldataComponent,
    RegisterCompanyDataComponent,
    RegisterCompanyContactPersonComponent,
    RegisterCompanyAddressComponent,
    OverviewContractSummaryComponent,
    BidComponent,
    OfferComponent,
    OfferCreateComponent,
    OfferStatusComponent,
    BidStatusComponent,
    BidCreateComponent,
    PendingProceduresComponent,
    CapitalIncreaseMoreInfoComponent,
    InvestReservationComponent,
    RegisterMangopayTermsComponent,
    InvestDocumentViewfinderComponent,
    UpdateListSliderComponent,
    YoutubeVideoPlayerComponent,
    ReserveInvestmentAdviceComponent,
    DeleteAccountComponent,
    RegisterCompanySpecificPartnerDocComponent,
    TabComponent,
    OverviewCommissionsComponent,
    InverstorProfileComponent,
    ActionSheetComponent,
    ActivationWalletMessageComponent,
    AlertComponent,
    ButtonComponent,
    CodeComponent,
    CreditCardComponent,
    DashboardSubmenuComponent,
    HelpComponent,
    HelpMarketplaceComponent,
    HelpMobileComponent,
    IncomeInfoComponent,
    InfoComponent,
    OveralMessageComponent,
    PasswordComponent,
    PasswordChangeComponent,
    PasswordRecoveryComponent,
    RenewSessionComponent,
    ToastComponent,
    WarningComponent,
    ProjectDetailComponent,
    ProjectDetailRowComponent,
    EmptyAlertComponent,
    FileCardComponent,
    FileComponent,
    FilterComponent,
    FilterMobileComponent,
    FilterOriginatorComponent,
    SpinnerComponent,
    ViewerComponent,
    ModalComponent,
    TimeLeftPipe,
    NameProfilePipe,
    IbanPipe,
    BICPipe,
    PowerWatiosPipe,
    Co2Pipe,
    NotZeroPipe,
    LocaleNumberPipe,
    LocalePercentNumberPipe,
    PhoneInputComponent,
    InputComponent,
    SkeletonCardListComponent,
    SkeletonCardComponent,
    SkeletonContactDataComponent,
    SkeletonInputComponent,
    SkeletonListComponent,
    SkeletonUpdateComponent,
    SidemenuIconComponent,
    NewCardComponent,
    NewButtonComponent,
    IconComponent,
    NewDetailComponent,
    AccordionComponent,
    ContentFeaturedComponent,
    NewDetailOverviewComponent,
    OverviiewStatusAvatarsComponent,
    OvervierCalculatorComponent,
    NewDocumentComponent,
    SliderComponent,
    CalendarPayOverviewComponent,
    FooterComponent,
    PageSubmenuComponent,
    PageTitleComponent,
    WalletCardComponent,
    MovementDetailComponent,
    BadgeGroupComponent,
    OverviewStatusSkeletonComponent,
    InvestSuccessTaxdownComponent,
    NotificationListComponent,
    SubscribeProjectNewsComponent,
    AskForCommunicationsComponent,
    RegisterFileCarruselComponent,
    OvervewMeetingListComponent,
    OvervewMeetingDirectComponent,
    OvervewMeetingChatComponent,
    OverviewMeetingDelegationComponent,
    OverviewMeetingModalComponent,
    OverviewMeetingDocumentsComponent,
    RegisterTaxdownComponent,
    RegisterTaxdownCodeComponent,
    ReferralListComponent,
    ReferralCardComponent,
    AppFormatIbanDirective,
    FormalizeSignatureComponent,
    InvestFinishSignComponent,
    NewGraphicComponent,
    NewSelectComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    TranslateModule,
    AgmCoreModule.forRoot({
      apiKey: gmapsApiKey,
    }),
    PdfViewerModule,
    Ng2TelInputModule,
    NgSelectModule,
    NgxYoutubePlayerModule
  ],
  exports: [
    HeaderComponent,
    SidemenuComponent,
    AvatarComponent,
    GraphicsNavComponent,
    ProductionGraphicComponent,
    DailyProductionGraphicComponent,
    IncomeGraphicComponent,
    IncomeAnnualGraphicComponent,
    GoogleMapsComponent,
    RegisterHeaderComponent,
    AutomaticInvestComponent,
    AffiliationLocalInvestComponent,
    CancelInvestMovementComponent,
    UpdateListComponent,
    DocumentationListComponent,
    CalendarPayComponent,
    CalendarPayMonthComponent,
    MoneyInComponent,
    MoneyOutComponent,
    MovementListComponent,
    MoneyOutChangeAccountComponent,
    OverviewDocumentationSectionComponent,
    OverviewSectionsComponent,
    OverviewInvestorsSectionComponent,
    OverviewAboutprojectSectionComponent,
    OverviewStatusProjectComponent,
    OverviewLocalInvestStepsComponent,
    OverviewMeetingComponent,
    LocalInvestStepsComponent,
    InvestComponent,
    InvestBodyComponent,
    InvestIfrComponent,
    InvestMangopayComponent,
    InvestPopupHeaderComponent,
    InvestReservedComponent,
    InvestSuccessComponent,
    InvestTestConvenienceComponent,
    RegisterComponent,
    RegisterWelcomeComponent,
    RegisterUploadSpecificDocumentComponent,
    RegisterSuccessComponent,
    RegisterPictureComponent,
    RegisterPersonLastStepComponent,
    RegisterPersonLastStepEndComponent,
    RegisterPersonLastStepDocumentComponent,
    RegisterPersonLastStepChecksComponent,
    RegisterPersonLastStepAdvantagesComponent,
    RegisterPersonComponent,
    RegisterPersonPhoneComponent,
    RegisterPersonNifInfoComponent,
    RegisterPersonNatinalityComponent,
    RegisterPersonNameComponent,
    RegisterPersonEmailComponent,
    RegisterPersonEditEmailComponent,
    RegisterPersonDocumentIdComponent,
    RegisterPersonCodeComponent,
    RegisterPersonCivilStatusComponent,
    RegisterPersonAddressComponent,
    RegisterIfameComponent,
    RegisterFullIframeComponent,
    RegisterFileComponent,
    RegisterCompanyComponent,
    RegisterCompanyRlnationalityComponent,
    RegisterCompanyRldocumentComponent,
    RegisterCompanyRldataComponent,
    RegisterCompanyDataComponent,
    RegisterCompanyContactPersonComponent,
    RegisterCompanyAddressComponent,
    OverviewContractSummaryComponent,
    BidComponent,
    OfferComponent,
    OfferCreateComponent,
    OfferStatusComponent,
    BidStatusComponent,
    BidCreateComponent,
    PendingProceduresComponent,
    CapitalIncreaseMoreInfoComponent,
    InvestReservationComponent,
    RegisterMangopayTermsComponent,
    InvestDocumentViewfinderComponent,
    UpdateListSliderComponent,
    YoutubeVideoPlayerComponent,
    ReserveInvestmentAdviceComponent,
    DeleteAccountComponent,
    RegisterCompanySpecificPartnerDocComponent,
    TabComponent,
    OverviewCommissionsComponent,
    InverstorProfileComponent,
    ActionSheetComponent,
    ActivationWalletMessageComponent,
    AlertComponent,
    ButtonComponent,
    CodeComponent,
    CreditCardComponent,
    DashboardSubmenuComponent,
    HelpComponent,
    HelpMarketplaceComponent,
    HelpMobileComponent,
    IncomeInfoComponent,
    InfoComponent,
    OveralMessageComponent,
    PasswordComponent,
    PasswordChangeComponent,
    PasswordRecoveryComponent,
    RenewSessionComponent,
    ToastComponent,
    WarningComponent,
    ProjectDetailComponent,
    ProjectDetailRowComponent,
    EmptyAlertComponent,
    FileCardComponent,
    FileComponent,
    FilterComponent,
    FilterMobileComponent,
    FilterOriginatorComponent,
    SpinnerComponent,
    ViewerComponent,
    ModalComponent,
    TimeLeftPipe,
    NameProfilePipe,
    IbanPipe,
    BICPipe,
    PowerWatiosPipe,
    Co2Pipe,
    NotZeroPipe,
    LocaleNumberPipe,
    LocalePercentNumberPipe,
    PhoneInputComponent,
    InputComponent,
    SkeletonCardListComponent,
    SkeletonCardComponent,
    SkeletonContactDataComponent,
    SkeletonInputComponent,
    SkeletonListComponent,
    SkeletonUpdateComponent,
    SidemenuIconComponent,
    NewCardComponent,
    NewButtonComponent,
    IconComponent,
    NewDetailComponent,
    AccordionComponent,
    ContentFeaturedComponent,
    NewDetailOverviewComponent,
    OverviiewStatusAvatarsComponent,
    OvervierCalculatorComponent,
    NewDocumentComponent,
    SliderComponent,
    CalendarPayOverviewComponent,
    FooterComponent,
    PageSubmenuComponent,
    PageTitleComponent,
    WalletCardComponent,
    MovementDetailComponent,
    BadgeGroupComponent,
    OverviewStatusSkeletonComponent,
    InvestSuccessTaxdownComponent,
    NotificationListComponent,
    SubscribeProjectNewsComponent,
    AskForCommunicationsComponent,
    RegisterFileCarruselComponent,
    OvervewMeetingListComponent,
    OvervewMeetingDirectComponent,
    OvervewMeetingChatComponent,
    OverviewMeetingDelegationComponent,
    OverviewMeetingModalComponent,
    OverviewMeetingDocumentsComponent,
    RegisterTaxdownComponent,
    RegisterTaxdownCodeComponent,
    ReferralListComponent,
    ReferralCardComponent,
    FormalizeSignatureComponent,
    InvestFinishSignComponent,
    NewGraphicComponent,
    NewSelectComponent
  ],
  entryComponents: [
    PublicLayoutComponent,
    PrivateLayoutComponent,
    ProductionGraphicComponent,
    DailyProductionGraphicComponent,
    IncomeGraphicComponent,
    IncomeAnnualGraphicComponent,
    GoogleMapsComponent,
    RegisterHeaderComponent,
    AutomaticInvestComponent,
    AffiliationLocalInvestComponent,
    CancelInvestMovementComponent,
    CalendarPayComponent,
    CalendarPayMonthComponent,
    MoneyInComponent,
    MoneyOutComponent,
    MovementListComponent,
    MoneyOutChangeAccountComponent,
    OverviewDocumentationSectionComponent,
    OverviewSectionsComponent,
    OverviewInvestorsSectionComponent,
    OverviewAboutprojectSectionComponent,
    OverviewStatusProjectComponent,
    OverviewLocalInvestStepsComponent,
    OverviewMeetingComponent,
    LocalInvestStepsComponent,
    InvestComponent,
    InvestBodyComponent,
    InvestIfrComponent,
    InvestMangopayComponent,
    InvestPopupHeaderComponent,
    InvestReservedComponent,
    InvestSuccessComponent,
    InvestTestConvenienceComponent,
    RegisterComponent,
    RegisterWelcomeComponent,
    RegisterUploadSpecificDocumentComponent,
    RegisterSuccessComponent,
    RegisterPictureComponent,
    RegisterPersonLastStepComponent,
    RegisterPersonLastStepEndComponent,
    RegisterPersonLastStepDocumentComponent,
    RegisterPersonLastStepChecksComponent,
    RegisterPersonLastStepAdvantagesComponent,
    RegisterPersonComponent,
    RegisterPersonPhoneComponent,
    RegisterPersonNifInfoComponent,
    RegisterPersonNatinalityComponent,
    RegisterPersonNameComponent,
    RegisterPersonEmailComponent,
    RegisterPersonEditEmailComponent,
    RegisterPersonDocumentIdComponent,
    RegisterPersonCodeComponent,
    RegisterPersonCivilStatusComponent,
    RegisterPersonAddressComponent,
    RegisterIfameComponent,
    RegisterFullIframeComponent,
    RegisterFileComponent,
    RegisterCompanyComponent,
    RegisterCompanyRlnationalityComponent,
    RegisterCompanyRldocumentComponent,
    RegisterCompanyRldataComponent,
    RegisterCompanyDataComponent,
    RegisterCompanyContactPersonComponent,
    RegisterCompanyAddressComponent,
    OverviewContractSummaryComponent,
    BidComponent,
    OfferComponent,
    OfferCreateComponent,
    OfferStatusComponent,
    BidStatusComponent,
    BidCreateComponent,
    PendingProceduresComponent,
    CapitalIncreaseMoreInfoComponent,
    InvestReservationComponent,
    RegisterMangopayTermsComponent,
    InvestDocumentViewfinderComponent,
    UpdateListSliderComponent,
    ReserveInvestmentAdviceComponent,
    DeleteAccountComponent,
    RegisterCompanySpecificPartnerDocComponent,
    InverstorProfileComponent,
    ActionSheetComponent,
    ActivationWalletMessageComponent,
    AlertComponent,
    ButtonComponent,
    CodeComponent,
    CreditCardComponent,
    DashboardSubmenuComponent,
    HelpComponent,
    HelpMarketplaceComponent,
    HelpMobileComponent,
    IncomeInfoComponent,
    InfoComponent,
    OveralMessageComponent,
    PasswordComponent,
    PasswordChangeComponent,
    PasswordRecoveryComponent,
    RenewSessionComponent,
    ToastComponent,
    WarningComponent,
    ProjectDetailComponent,
    ProjectDetailRowComponent,
    EmptyAlertComponent,
    FileCardComponent,
    FileComponent,
    FilterComponent,
    FilterMobileComponent,
    SpinnerComponent,
    ViewerComponent,
    ModalComponent,
    PhoneInputComponent,
    InputComponent,
    SkeletonCardListComponent,
    SkeletonCardComponent,
    SkeletonContactDataComponent,
    SkeletonInputComponent,
    SkeletonListComponent,
    SkeletonUpdateComponent,
    NewCardComponent,
    NewButtonComponent,
    IconComponent,
    NewDetailComponent,
    AccordionComponent,
    ContentFeaturedComponent,
    NewDetailOverviewComponent,
    OverviiewStatusAvatarsComponent,
    OvervierCalculatorComponent,
    NewDocumentComponent,
    SliderComponent,
    CalendarPayOverviewComponent,
    FooterComponent,
    PageSubmenuComponent,
    PageTitleComponent,
    WalletCardComponent,
    MovementDetailComponent,
    BadgeGroupComponent,
    OverviewStatusSkeletonComponent,
    InvestSuccessTaxdownComponent,
    NotificationListComponent,
    SubscribeProjectNewsComponent,
    AskForCommunicationsComponent,
    RegisterFileCarruselComponent,
    OvervewMeetingListComponent,
    OvervewMeetingDirectComponent,
    OvervewMeetingChatComponent,
    OverviewMeetingDelegationComponent,
    OverviewMeetingModalComponent,
    OverviewMeetingDocumentsComponent,
    RegisterTaxdownComponent,
    RegisterTaxdownCodeComponent,
    ReferralListComponent,
    ReferralCardComponent,
    FormalizeSignatureComponent,
    InvestFinishSignComponent,
    NewGraphicComponent,
    NewSelectComponent
  ],
  providers: [OffersService, CameraPreview],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
