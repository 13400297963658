<div class="flex flex-col md:flex-row md:gap-32">
  <div class="w-full rounded-t-2xl md:rounded-2xl border-t border-x md:border border-eggplant-100/[0.1] bg-white pt-6 md:pb-6 content" [ngClass]="{'rounded-b-2xl': list.length <= 2 }">
    <div *ngFor="let item of firstList; let i = index" class="lst flex appear">
      <div class="p-[18px]">
        <fun-icon [name]="item.icon" [style]="'stroke-[4px] stroke-eggplant-100 w-20 h-20'"></fun-icon>
      </div>
      <div class="w-full py-8 cursor-pointer border-b border-b-eggplant-100/[0.1]" [ngClass]="{'md:!border-none': (i !== 0), '!border-none': (list.length <= 2 && i == list.length - 1) }" (click)="iconClicked(item.id)">
        <div class="flex justify-between gap-x-12">
          <div>
            <p class="font-sans text-l text-eggplant-100">{{ item.title }}</p>
            <p class="font-sans text-s text-eggplant-100/[0.55]">{{ item.subtitle }}</p>
          </div>
          <div class="pt-16 pr-24">
            <fun-icon [name]="'chevron-right'" [style]="'stroke-[4px] stroke-eggplant-100/[0.4] w-[20px] h-[20px]'"></fun-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="list.length > 2" class="w-full rounded-b-2xl md:rounded-2xl border-b border-x md:border  border-eggplant-100/[0.1] bg-white pb-6 md:pt-6 content">
    <div *ngFor="let item of secondList; let i = index" class="lst flex appear">
      <div class="p-[18px]">
        <fun-icon [name]="item.icon" [style]="'stroke-[4px] stroke-eggplant-100 w-20 h-20'"></fun-icon>
      </div>
      <div class="w-full py-8 cursor-pointer border-b-eggplant-100/[0.1]" [ngClass]="{'border-b': i < secondList.length - 1 }" (click)="iconClicked(item.id)">
        <div class="flex justify-between gap-x-12">
          <div>
            <p class="font-sans text-l text-eggplant-100">{{ item.title }}</p>
            <p class="font-sans text-s text-eggplant-100/[0.55]">{{ item.subtitle }}</p>
          </div>
          <div class="pt-16 pr-24">
            <fun-icon [name]="'chevron-right'" [style]="'stroke-[4px] stroke-eggplant-100/[0.4] w-[20px] h-[20px]'"></fun-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
