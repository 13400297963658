// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  configFilePath: 'assets/configuration/configuration.dev.json',
  env: 'dev',
  endPointLW: 'https://sandbox-webkit.lemonway.fr/Fundeen/dev?moneyInToken=',
  endPointHubspot: 'https://test.fundeen.com/api/HubSpotInvest',
  activateInvest: true,
  activateMarketplace: true,
  enableTagManager: false,
  enableDatalayer: false,
  enableAutomaticInvest: true,
  enableCommunications: false,
  rollbarToken: false,
  origin: 'https://app.dev.fundeen.com',
  amplitudeToken: 'fa2bd22db5953fd1acab4049bb4b681b',
  askForCommunications: false,
  endPointN8NReserve: 'https://n8n.fundeen.com/webhook-test/6f7b288e-1efe-4504-a6fd-660931327269',
  enableRicenergyPortfolio: false,
  strapiCommonToken: 'af42cd3e1953a76b691c589f783a6a29a96cbf68a461d74152da7f9230c11daea42bcb317905a8f28f08380b22cf9bbb1e61c60cfd850bad8d2357b4b893208ce7182d49af2081d50e8db05d3f0962f3d0d2d6d940d8632be94116932fcc8092dc8403d6aff1c6183b4e124103b8e7385a9f8ddd92f473ec1c6929793af051ae',
  strapiUrl: 'http://localhost:1337', // http://localhost:1337 para apuntar a local y https://fundeen-back-strapi-dev-3725502e049c.herokuapp.com para apuntar a dev
  enablePushNotifications: true,
  oneSignalId: '40b050a2-8592-4369-bd42-5b7f0f6d69d6',
  oneSignalKey: 'ZWNkY2M5YzgtNzJlZC00MmY0LWIwODktODBmNTc5NzQxZmRm',
  signAndInvestKey: 'rzEGe2UOHFYiH7gtNZS70w=='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
