<div class="py-32">
  <div *ngIf="loading"><fun-spinner></fun-spinner></div>
  <div *ngIf="!loading">
    <div *ngIf="signType === 'lleidanet' && lleidanetOpen" class="md:mt-72 py-72 w-full flex flex-col gap-72 items-center justify-center content-center appear bg-white rounded-2xl">
      <div><fun-icon [name]="'file-text'" [style]="'w-72 h-[86px] stroke-[4px] stroke-eggplant-70 animate-gradientX'"></fun-icon></div>
      <p class="text-center font-sans text-h600 md:text-h800 text-eggplant-70 animate-gradientX">{{ 'SIGN.PROCCESS_OTHER_WINDOW' | translate }}</p>
      <div class="flex items-center justify-center">
        <fun-new-button [buttonType]="'colorFull'" [size]="'xl'" title="{{ 'bid-status.sign-now' | translate }}"
        iconPosition="right" icon="file-text" (eventClick)="controlOveralBtn(1)" [classExtra]="' w-auto mx-auto '"></fun-new-button>
      </div>
    </div>
    <div *ngIf="signType === 'signaturit' && signStatus !== 'KO' && signStatus !== 'completed'" class="w-full h-full pt-32 min-h-[520px] md:min-h-[620px]">
      <iframe
      class="w-full h-full min-h-[520px] md:min-h-[620px]"
        [src]="urlSafe"
        frameborder="0"
        #iframe
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
    ></iframe>
  </div>
    <div *ngIf="signStatus === 'KO' || signStatus === 'completed'" class="mt-0 md:mt-72 py-32 bg-white rounded-2xl">
        <fun-overal-message [text]="text"
          [image]="image" [btnList]="btnList" (btnEvent)="controlOveralBtn($event)"></fun-overal-message>
    </div>
  </div>
  <!---->
</div>